import React, { useRef, useEffect, useState } from "react";
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import GeoJSON from "geojson";
import ReactModal from "react-modal";
import HotelIcon from "@mui/icons-material/Hotel";
import MapIcon from "@mui/icons-material/Map";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import ExploreIcon from "@mui/icons-material/Explore";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import FastfoodIcon from "@mui/icons-material/Fastfood";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import PersonIcon from "@mui/icons-material/Person";
import Autocomplete from "@mui/material/Autocomplete";
import LocalActivityIcon from "@mui/icons-material/LocalActivity";
import LocalPlayIcon from "@mui/icons-material/LocalPlay";
import { Link, useParams } from "react-router-dom";

const mapContainerStyle = {
  width: window.innerWidth <= 768 ? "100vw" : "100vw",
  height: "50vh",
};

const Map = ({ items }) => {
  const mapContainerRef = useRef(null);
  const [map, setMap] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [videoFormat, setVideoFormat] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedName, setSelectedName] = useState(null);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedState, setSelectedState] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [selectedCluster, setSelectedCluster] = useState(null);
  const apiUrl = process.env.REACT_APP_MAPBOX_TOKEN;
  const popupContentRef = useRef(null);

  const handlePointClick = (videoLink, format, properties) => {
    console.log(properties);
    setSelectedImage(properties.imageUrl);
    setSelectedCategory(properties.category);
    setSelectedName(properties.name);
    setSelectedAddress(properties.address);
    setSelectedCountry(properties.country);
    setSelectedState(properties.state);
    setSelectedCity(properties.city);
    setModalOpen(true);
  };

  // Modal styles
  const customStyles = {
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.75)", // This is a semi-transparent black color
    },
    content: {
      border: "2px solid #1bb6ca", // This adds a thin border
      borderRadius: "10px", // This rounds the edges
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
  };

  function getRandomOffset() {
    // Generate a random number between -0.001 and 0.001 (adjust the range as needed)
    const offset = (Math.random() - 0.5) * 0.002;
    return offset;
  }

  useEffect(() => {
    const map = new mapboxgl.Map({
      container: mapContainerRef.current,
      accessToken:
        "pk.eyJ1IjoiamFtaWVib2ZhbmV5IiwiYSI6ImNrbGYzY2lmbzBqZ3Uyb3Fsam80eTBnMzQifQ.yWDpUk0OH_jFMLkCHsIEqw",
      style: "mapbox://styles/jamiebofaney/ckxwpmjjw7zcp14mofj8y40sh",
      center: [-80.09033677272201, 10.33216206095227],
      zoom: 1,
    });
    map.addControl(new mapboxgl.NavigationControl(), "bottom-right");
    map.scrollZoom.disable();

    map.on("load", async () => {
      // Add a new source from our GeoJSON data and
      // set the 'cluster' option to true. GL-JS will
      // add the point_count property to your source data.
      const res = await fetch(
        `https://44cfealud3.execute-api.ap-southeast-2.amazonaws.com/prod/video/video`
      );
      const journeys = await res.json();
      const geojson = GeoJSON.parse(
        items.filter(
          (item) => item._geoloc && item._geoloc.lat && item._geoloc.lng
        ),
        {
          Point: ["_geoloc.lat", "_geoloc.lng"],
        }
      );

      console.log(geojson);

      var bounds = new mapboxgl.LngLatBounds();
      console.log(geojson);
      geojson.features.forEach(function (feature) {
        if (feature.geometry && feature.geometry.coordinates) {
          feature.geometry.coordinates[1] += getRandomOffset(); // Latitude
          feature.geometry.coordinates[0] += getRandomOffset(); // Longitude
          bounds.extend(feature.geometry.coordinates);
        }
      });

      map.addSource("videos", {
        type: "geojson",
        data: geojson,
        cluster: true,
        clusterMaxZoom: 14, // Max zoom to cluster points on
        clusterRadius: 50, // Radius of each cluster when clustering points (defaults to 50)
      });

      map.addLayer({
        id: "unclustered-point",
        type: "circle",
        source: "videos",
        filter: ["!", ["has", "point_count"]],
        paint: {
          "circle-color": "#11b4da",
          "circle-radius": 6,
          "circle-stroke-width": 1,
          "circle-stroke-color": "#fff",
        },
      });

      map.addLayer({
        id: "clusters",
        type: "circle",
        source: "videos",
        filter: ["has", "point_count"],
        paint: {
          // Use step expressions (https://docs.mapbox.com/mapbox-gl-js/style-spec/#expressions-step)
          // with three steps to implement three types of circles:
          //   * Blue, 20px circles when point count is less than 100
          //   * Yellow, 30px circles when point count is between 100 and 750
          //   * Pink, 40px circles when point count is greater than or equal to 750
          "circle-color": [
            "step",
            ["get", "point_count"],
            "#B6D99C",
            100,
            "#B1D7D8",
            750,
            "#f28cb1",
          ],
          "circle-radius": [
            "step",
            ["get", "point_count"],
            20,
            100,
            30,
            750,
            40,
          ],
        },
      });

      map.addLayer({
        id: "cluster-count",
        type: "symbol",
        source: "videos",
        filter: ["has", "point_count"],
        layout: {
          "text-field": "{point_count_abbreviated}",
          "text-font": ["DIN Offc Pro Medium", "Arial Unicode MS Bold"],
          "text-size": 12,
        },
      });

      // inspect a cluster on click
      map.on("click", "clusters", (e) => {
        const features = map.queryRenderedFeatures(e.point, {
          layers: ["clusters"],
        });
        const clusterId = features[0].properties.cluster_id;
        map
          .getSource("videos")
          .getClusterExpansionZoom(clusterId, (err, zoom) => {
            if (err) return;

            map.easeTo({
              center: features[0].geometry.coordinates,
              zoom: zoom,
            });
          });
      });

      map.fitBounds(bounds, {
        padding: { top: 150, bottom: 150, left: 150, right: 150 },
        maxZoom: 12,
      });

      map.on("click", "unclustered-point", (e) => {
        map.on("mouseenter", "unclustered-point", () => {
          map.getCanvas().style.cursor = "pointer";
        });
        map.on("mouseleave", "unclustered-point", () => {
          map.getCanvas().style.cursor = "";
        });
        const coordinates = e.features[0].geometry.coordinates.slice();

        while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
          coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
        }

        const experiences = JSON.parse(e.features[0].properties.experiences);
        experiences.forEach((experience, index) => {
          const videoLink = experience.videoLink;
          const videoName = `Video ${index + 1}`; // Since there's no videoName property, we'll just use a placeholder
          const format = "mp4"; // Since there's no format property, we'll just use a placeholder

          handlePointClick(videoLink, format, e.features[0].properties);
        });
      });

      map.on("mouseenter", "clusters", () => {
        map.getCanvas().style.cursor = "pointer";
      });
      map.on("mouseleave", "clusters", () => {
        map.getCanvas().style.cursor = "";
      });
    });

    setMap(map);

    return () => map.remove();
  }, [items]);

  return (
    <div>
      <div ref={mapContainerRef} style={mapContainerStyle} />
      <ReactModal
        isOpen={modalOpen}
        style={customStyles}
        onRequestClose={() => setModalOpen(false)}
        contentLabel="Item Modal"
      >
        <div style={{ display: "flex", alignItems: "center", margin: "10px" }}>
          {selectedCategory === "Hotel" ? (
            <HotelIcon style={{ color: "#415063" }} />
          ) : selectedCategory === "Destination" ? (
            <LocationOnIcon style={{ color: "#415063" }} />
          ) : selectedCategory === "Activity" ? (
            <CameraAltIcon style={{ color: "#415063" }} />
          ) : selectedCategory === "Food" ? (
            <FastfoodIcon style={{ color: "#415063" }} />
          ) : selectedCategory === "ThemePark" ? (
            <LocalActivityIcon style={{ color: "#415063" }} />
          ) : selectedCategory === "Journey" ? (
            <MapIcon style={{ color: "#415063" }} />
          ) : selectedCategory === "User" ? (
            <PersonIcon style={{ color: "#415063" }} />
          ) : null}
          <p style={{ marginLeft: "10px" }}>{selectedCategory}</p>
        </div>
        <img
          src={selectedImage}
          alt={selectedName}
          style={{ width: "100%", height: "auto" }}
        />
        <h2>{selectedName}</h2>
        <p>{selectedAddress}</p>
      </ReactModal>
    </div>
  );
};

export default Map;
