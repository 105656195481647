import React, { useEffect, useState, useContext } from "react";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import FavoriteIcon from "@mui/icons-material/Favorite";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import { Paper } from "@mui/material";
import Chip from "@mui/material/Chip";
import Experience from "../experience/experience";
import UserContext from "../../UserContext";
import Swal from "sweetalert2";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import ReactCountryFlag from "react-country-flag";
import DialogTitle from "@mui/material/DialogTitle";
import { Link } from "react-router-dom";
import facebook from "../../Images/facebook.png";
import instagram from "../../Images/instagram.svg";
import tiktok from "../../Images/tiktok.svg";
import x from "../../Images/x.png";
import youtube from "../../Images/youtube.png";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import CloseIcon from "@mui/icons-material/Close";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { FilePond, registerPlugin } from "react-filepond";
import {
  ref as storageRef,
  uploadBytesResumable,
  deleteObject,
  ref,
  getDownloadURL,
} from "firebase/storage";
import { Helmet } from "react-helmet";
import {
  FormControl,
  Select,
  MenuItem,
  FormHelperText,
  Grid,
  TextField,
  Rating,
  Typography,
  Button,
  Box,
  IconButton,
} from "@mui/material";
import { InputLabel } from "@mui/material";
import {
  collection,
  getDoc,
  getDocs,
  updateDoc,
  deleteDoc,
  doc,
  arrayUnion,
  arrayRemove,
  increment,
} from "firebase/firestore";
import { Timestamp } from "firebase/firestore";
import { db, storage } from "../../firebase";
import { formatDistanceStrict } from "date-fns";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { styled } from "@mui/system";
import "./item.css";
// Import the Image EXIF Orientation and Image Preview plugins
// Note: These need to be installed separately
// `npm i filepond-plugin-image-preview filepond-plugin-image-exif-orientation --save`
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";

// Register the plugins
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

const StyledAccordianDetails = styled(AccordionDetails)({
  backgroundColor: "#fff",
  width: "90%",
  marginLeft: "auto",
  marginRight: "auto",
  marginBottom: "10px",
  marginTop: "10px",
});

const StyledAccordionSummary = styled(AccordionSummary)({
  backgroundColor: "#fff",
  width: "90%",
  marginLeft: "auto",
  marginRight: "auto",
  marginBottom: "10px",
  marginTop: "10px",
});

const DestinationItem = ({ item, userId, onItemUpdate }) => {
  const [experiences, setExperiences] = useState([]);
  const [newExperience, setNewExperience] = useState({
    experience: "",
    videoLink: "",
  });
  const [rating, setRating] = useState(item.rating);
  const [startDate, setStartDate] = useState(item.startDate);
  const [endDate, setEndDate] = useState(item.endDate);
  const [review, setReview] = useState(item.review);
  const [currentItem, setCurrentItem] = useState(null);
  const [roomType, setRoomType] = useState(item.roomType);
  const [userData, setUserData] = useState(null);
  const [liked, setLiked] = useState(
    item && item.likes ? item.likes.includes(userId) : false
  );
  const [comments, setComments] = useState([]);
  const [showComments, setShowComments] = useState(false);
  const user = useContext(UserContext);
  const value = 4;
  const [open, setOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isPhotoModalOpen, setIsPhotoModalOpen] = useState(false);
  const [selectedPhotoUrl, setSelectedPhotoUrl] = React.useState(null);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [experiencePrompts, setExperiencePrompts] = useState([]);
  const [uploadMetadata, setUploadMetadata] = useState([]);
  const [files, setFiles] = useState([]);
  const {
    control,
    register,
    handleSubmit,
    watch,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    defaultValues: {
      experiences: [{ experience: "", videoLink: "" }],
      // other form fields...
    },
  });

  // Watch the videoLink field
  const videoLink = watch("newExperience.videoLink");

  // Initial state of the form
  const [form, setForm] = useState({
    startDate: "",
    endDate: "",
    roomType: "",
    review: "",
    rating: 0,
  });

  useEffect(() => {
    if (item && item.userId) {
      const fetchUserData = async () => {
        const userRef = doc(db, "Users", item.userId);
        const userSnap = await getDoc(userRef);
        const data = userSnap.data();
        setUserData(data);
        console.log(data);
      };
      fetchUserData();
    }
  }, [item]);

  useEffect(() => {
    const fetchExperiencePrompts = async () => {
      const experiencePromptsCollection = collection(
        db,
        "Experiences",
        "jfHCIMDg5Uj2PCkWxBEd",
        "Destinations",
        "YVqifqIolRU7Gf6xg7fK",
        "ExperiencePrompts"
      );
      const experiencePromptsSnapshot = await getDocs(
        experiencePromptsCollection
      );
      const experiencePromptsData = experiencePromptsSnapshot.docs.map((doc) =>
        doc.data()
      );
      setExperiencePrompts(experiencePromptsData);
    };

    fetchExperiencePrompts();
  }, []);

  const { fields, append, remove } = useFieldArray({
    control,
    name: "experiences",
  });

  const validateVideoLink = (relatedExperience) => (videoLink) => {
    console.log("videoLink:", videoLink);
    console.log("relatedExperience:", relatedExperience);
    const youtubeRegex =
      /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
    const instagramRegex =
      /(http|https):\/\/(www\.)?instagram.com\/(p|reel|tv)\/(.*\/)?/;
    const tiktokRegex =
      /(http|https):\/\/(www\.|vt\.)?tiktok.com\/(@.+\/video\/.+|Z.+\/)/;

    if (relatedExperience && !videoLink) {
      return "A video link is required when an experience is selected";
    } else if (
      videoLink &&
      !(
        youtubeRegex.test(videoLink) ||
        instagramRegex.test(videoLink) ||
        tiktokRegex.test(videoLink)
      )
    ) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Please enter a valid YouTube, Instagram, or TikTok link",
        customClass: {
          container: "swal",
        },
      });
      return false;
    }
    return true;
  };

  // Update the newExperience object when the videoLink field changes
  useEffect(() => {
    setNewExperience((prev) => ({ ...prev, videoLink }));
  }, [videoLink]);

  // Update the form state when the item prop changes
  useEffect(() => {
    if (item) {
      let startDate = "";
      let endDate = "";

      if (typeof item.startDate === "number" && !isNaN(item.startDate)) {
        startDate = new Date(item.startDate).toISOString().split("T")[0];
      }

      if (typeof item.endDate === "number" && !isNaN(item.endDate)) {
        endDate = new Date(item.endDate).toISOString().split("T")[0];
      }

      setForm({
        startDate,
        endDate,
        roomType: item.roomType || "",
        review: item.review || "",
        rating: item.rating || 0,
      });
    }
  }, [item]);

  const EditDialog = ({ item, open, handleClose }) => {
    const [localForm, setLocalForm] = useState({
      startDate:
        item && item.startDate
          ? new Date(item.startDate).toISOString().split("T")[0]
          : "",
      endDate:
        item && item.endDate
          ? new Date(item.endDate).toISOString().split("T")[0]
          : "",
      roomType: item ? item.roomType || "" : "",
      review: item ? item.review || "" : "",
      rating: item ? item.rating || 0 : 0,
      journey: item ? item.journey || null : null,
    });

    useEffect(() => {
      if (item) {
        setLocalForm({
          startDate: item.startDate
            ? new Date(item.startDate).toISOString().split("T")[0]
            : "",
          endDate: item.endDate
            ? new Date(item.endDate).toISOString().split("T")[0]
            : "",
          roomType: item.roomType || "",
          review: item.review || "",
          rating: item.rating || 0,
          journey: item.journey || null,
        });
      }
    }, [item]);

    // Function to handle form change
    const handleFormChange = (event) => {
      setLocalForm({
        ...localForm,
        [event.target.name]: event.target.value,
      });
    };

    // Handle journey change
    const handleJourneyChange = (event) => {
      setLocalForm({
        ...localForm,
        journey: event.target.value,
      });
    };

    // Update the item and state when the form is submitted
    const handleFormSubmit = async (event) => {
      event.preventDefault();

      // Convert startDate and endDate from strings to Timestamps
      const startDate = localForm.startDate
        ? Timestamp.fromDate(new Date(localForm.startDate))
        : null;
      const endDate = form.endDate
        ? Timestamp.fromDate(new Date(localForm.endDate))
        : null;
      console.log(localForm);
      // Prepare the new journey details
      const newJourneyDetails = {
        ...localForm,
        startDate: startDate,
        endDate: localForm.endDate ? new Date(localForm.endDate) : null,
        rating: localForm.rating,
        review: localForm.review,
        roomType: localForm.roomType,
        experiences: [...item.experiences], // Make a copy of experiences to trigger useEffect
      };
      console.log(newJourneyDetails);

      // Update the journey details in the database
      const itemRef = doc(db, "JourneyItems", item.objectID);
      console.log(newJourneyDetails);
      updateDoc(itemRef, newJourneyDetails)
        .then(async () => {
          // Fetch the updated document from Firestore
          const updatedDocSnapshot = await getDoc(itemRef);
          const updatedItem = {
            ...updatedDocSnapshot.data(),
            id: updatedDocSnapshot.id,
          };

          // If a journey is selected, add the updated item to the journey
          if (localForm.journey) {
            console.log("Adding updated item to journey...");

            // Get a reference to the journey document
            const journeyRef = doc(db, "Journeys", localForm.journey.id);

            // Fetch the current journey document
            const journeySnap = await getDoc(journeyRef);
            let journeyData = journeySnap.data();

            // If the journey doesn't have an items array, create it
            if (!journeyData.items) {
              journeyData.items = [];
            }

            // Add the updated item to the journey's items array
            journeyData.items.push({
              id: updatedItem.id,
              category: updatedItem.category,
              name: updatedItem.name,
            });

            // Update the journey document with the new items array
            await updateDoc(journeyRef, {
              items: journeyData.items,
            });

            console.log("Updated item added to journey");
          }

          // Update the local state
          setLocalForm(updatedItem);
          setRating(updatedItem.rating); // Update the rating state
          setStartDate(updatedItem.startDate); // Update the startDate state
          setEndDate(updatedItem.endDate); // Update the endDate state
          setReview(updatedItem.review); // Update the review state

          // Display a success message
          Swal.fire("Success!", "Your changes have been saved.", "success");

          // Close the dialog
          handleClose();
        })
        .catch((error) => {
          console.error("Error updating document: ", error);
        });
    };

    return (
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle>Edit Experience</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            name="startDate"
            label="Start Date"
            type="date"
            fullWidth
            value={localForm.startDate}
            onChange={handleFormChange}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextField
            margin="dense"
            name="endDate"
            label="End Date"
            type="date"
            fullWidth
            value={localForm.endDate}
            onChange={handleFormChange}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextField
            margin="dense"
            name="review"
            label="Review"
            type="text"
            fullWidth
            value={localForm.review}
            onChange={handleFormChange}
          />
          <Rating
            name="rating"
            value={localForm.rating}
            onChange={(event, newValue) => {
              setLocalForm({
                ...localForm,
                rating: newValue,
              });
            }}
          />
          <Grid item>
            <InputLabel className="inputLabel" id="journey-label">
              Add to an existing Journey (optional - this can be added later)
            </InputLabel>
            <Controller
              name="journey"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <FormControl>
                  <Select
                    {...field}
                    labelId="journey-label"
                    id="journey-select" // Add this line
                    value={localForm.journey}
                    onChange={handleJourneyChange}
                    displayEmpty // Add this line
                  >
                    <MenuItem value="">
                      <em>Select a journey</em>
                    </MenuItem>
                    {userData.journeys.map((journey) => (
                      <MenuItem key={journey.id} value={journey}>
                        {journey.name}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText>
                    Optional - this can be added later
                  </FormHelperText>
                </FormControl>
              )}
            />
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button type="submit" onClick={handleFormSubmit}>
            Update
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  // Function to handle opening the dialog
  const handleClickOpen = (item) => {
    setCurrentItem(item);
    setOpen(true);
  };

  // Function to handle closing the dialog
  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (userId && item && item.likes) {
      setLiked(item.likes.includes(userId));
    }
  }, [userId, item]);

  useEffect(() => {
    setExperiences(item.experiences);
  }, [item.experiences]);

  const handleLike = async () => {
    if (!userId) {
      Swal.fire({
        icon: "info",
        title: "Please sign in",
        text: "You need to be signed in to like an item",
      });
      return;
    }

    const itemRef = doc(db, "JourneyItems", item.objectID);
    const userRef = doc(db, "Users", userId);

    if (liked) {
      await updateDoc(itemRef, {
        likes: arrayRemove(userId),
        totalLikes: increment(-1), // decrement totalLikes by 1
      });
      await updateDoc(userRef, {
        likedItems: arrayRemove(item.objectID),
      });
    } else {
      await updateDoc(itemRef, {
        likes: arrayUnion(userId),
        totalLikes: increment(1), // increment totalLikes by 1
      });
      await updateDoc(userRef, {
        likedItems: arrayUnion(item.objectID),
      });
    }

    setLiked(!liked);
  };

  console.log(item);

  const handleDelete = async () => {
    // Confirm the user wants to delete the item
    const confirmation = await Swal.fire({
      title: "Are you sure?",
      text: "You will not be able to recover this item!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, keep it",
    });

    if (confirmation.isConfirmed) {
      // Delete the document from the database
      const itemRef = doc(db, "JourneyItems", item.objectID);
      await deleteDoc(itemRef);

      // Update user stats
      const userRef = doc(db, "Users", user.uid);
      const userSnap = await getDoc(userRef);
      let userData = userSnap.data();

      const categoryStats = userData.stats[item.category];
      if (categoryStats) {
        categoryStats.totalSubmissions -= 1;
        categoryStats.points -= 10; // replace with actual points calculation
        // Delete unique submission
        if (categoryStats.uniqueSubmissions[item.objectID]) {
          delete categoryStats.uniqueSubmissions[item.objectID];
        }
        userData.stats[item.category] = categoryStats;
      }

      // Update location stats
      let cityStats;
      if (
        userData.stats.locations[item.country] &&
        userData.stats.locations[item.country][item.state]
      ) {
        cityStats =
          userData.stats.locations[item.country][item.state][item.city];
      }

      if (cityStats) {
        cityStats.totalItems -= 1;
        cityStats.itemsByCategory[item.category] -= 1;
        // Delete unique hotel submission from location stats
        if (
          item.category === "Hotels" &&
          cityStats.itemsByCategory.Hotels[item.objectID]
        ) {
          delete cityStats.itemsByCategory.Hotels[item.objectID];
        }
        userData.stats.locations[item.country][item.state][item.city] =
          cityStats;
      }

      await updateDoc(userRef, userData); // update user in Firestore

      // Display a success message
      Swal.fire("Deleted!", "Your item has been deleted.", "success");
    }
  };

  const handleNewExperienceChange = (event) => {
    if (event.target.name === "experience") {
      setNewExperience({
        ...newExperience,
        experience: event.target.value,
        name: experiencePrompts[event.target.value]?.name || "",
        customExperience: "", // Clear the customExperience field
      });
    } else if (event.target.name === "customExperience") {
      setNewExperience({
        ...newExperience,
        experience: "", // Clear the experience field
        name: event.target.value,
        customExperience: event.target.value,
      });
    } else {
      setNewExperience({
        ...newExperience,
        [event.target.name]: event.target.value,
      });
    }
  };

  const handleNewExperienceSubmit = async (event) => {
    event.preventDefault();

    // Log the form values
    console.log(newExperience);

    // Check if an experience is selected or a custom experience is provided
    if (
      (!newExperience || newExperience.experience == null) &&
      !newExperience.customExperience
    ) {
      Swal.fire({
        title: "Error!",
        text: "Please select an experience or enter a custom experience",
        icon: "error",
        customClass: {
          container: "swal",
        },
      });
      return;
    }

    // Append the new experience to the experiences array
    const newExperiences = [...experiences, newExperience];

    // Update the item in the database
    const itemRef = doc(db, "JourneyItems", item.objectID);
    await updateDoc(itemRef, {
      experiences: newExperiences,
    });

    // Update the local state
    setExperiences(newExperiences);

    // Close the modal
    setIsModalOpen(false);
  };

  const handleExperienceDelete = async (index) => {
    // Remove the experience at the given index from the experiences array
    const newExperiences = experiences.filter((experience, i) => i !== index);

    // Update the item in the database
    const itemRef = doc(db, "JourneyItems", item.objectID);
    await updateDoc(itemRef, {
      experiences: newExperiences,
    });

    // Update the local state
    setExperiences(newExperiences);
  };

  function isPast(date) {
    let now = new Date();
    now.setHours(0, 0, 0, 0);
    return date.setHours(0, 0, 0, 0) < now;
  }

  let status = "Upcoming";
  let dateToCompare = new Date();

  function isSameDay(date1, date2) {
    return (
      date1.getFullYear() === date2.getFullYear() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getDate() === date2.getDate()
    );
  }

  // Convert the startDate to the local timezone
  let localStartDate = new Date(item.startDate);
  localStartDate.setMinutes(
    localStartDate.getMinutes() - localStartDate.getTimezoneOffset()
  );

  // Get the current date without time
  let currentDate = new Date();
  currentDate.setHours(0, 0, 0, 0);

  // Get the local start date without time
  let localStartDateWithoutTime = new Date(localStartDate);
  localStartDateWithoutTime.setHours(0, 0, 0, 0);

  if (item.endDate) {
    let endDate = new Date(item.endDate);
    endDate.setHours(0, 0, 0, 0);
    if (isPast(endDate)) {
      status = "Complete";
      dateToCompare = endDate;
    } else if (isPast(localStartDateWithoutTime) && !isPast(currentDate)) {
      status = "In Progress";
      dateToCompare = new Date();
    } else {
      status = "Upcoming";
      dateToCompare = localStartDate;
    }
  } else {
    if (isSameDay(localStartDateWithoutTime, currentDate)) {
      status = "In Progress";
      dateToCompare = new Date();
    } else if (isPast(localStartDateWithoutTime)) {
      status = "Complete";
      dateToCompare = localStartDate;
    } else {
      status = "Upcoming";
      dateToCompare = localStartDate;
    }
  }

  const handleDeletePhoto = async (photoImg) => {
    try {
      // Assuming `item` is available in your component's state or context
      const journeyItemId = item.objectID; // Extract journeyItemId from item

      // Assuming `username` is available in your component's state or context
      const username = `${userData.userName}`;

      // Step 1: Delete the photo from Firebase Storage
      const photoRef = ref(storage, `UserImages/${username}/${photoImg}`);
      await deleteObject(photoRef);

      // Step 2: Delete the photo from the Firestore document
      // Reference to the specific JourneyItems document
      const journeyItemDocRef = doc(db, "JourneyItems", journeyItemId);

      // Fetch the current document to get the photos array
      const docSnap = await getDoc(journeyItemDocRef);
      if (docSnap.exists()) {
        const journeyItemData = docSnap.data();
        const updatedPhotos = journeyItemData.photos.filter(
          (photo) => photo.name !== photoImg
        );

        // Update the document with the new photos array
        await updateDoc(journeyItemDocRef, {
          photos: updatedPhotos,
        });
      } else {
        console.log("No such document!");
      }

      // Show success message or update UI accordingly
      Swal.fire({
        icon: "success",
        title: "Photo deleted successfully",
      });

      // Optionally, update your component's state to reflect the change
    } catch (error) {
      console.error("Error deleting photo: ", error);
      // Show error message
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
      });
    }
  };
  const handlePhotoClick = (photoUrl) => {
    setSelectedPhotoUrl(photoUrl);
    setOpenDialog(true);
  };

  const handleProcess = (
    fieldName,
    file,
    metadata,
    load,
    error,
    progress,
    abort
  ) => {
    const filePath = `UserImages/${userData.userName}/${file.name}`;
    const fileRef = storageRef(storage, filePath);
    const uploadTask = uploadBytesResumable(fileRef, file);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progressPercentage =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        progress(true, snapshot.bytesTransferred, snapshot.totalBytes);
        console.log("Upload is " + progressPercentage + "% done");
      },
      (err) => {
        error(err.message);
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          load(downloadURL);
          console.log("File available at", downloadURL);

          // Assuming setUploadMetadata updates the local state, keep it if needed
          setUploadMetadata((prevMetadata) => [
            ...prevMetadata,
            { name: file.name, url: downloadURL },
          ]);

          // Step 2: Update Firestore with Image Metadata
          // Assuming you have the Firestore document path or ID to update
          const docRef = doc(db, "JourneyItems", `${item.objectID}`);
          updateDoc(docRef, {
            photos: arrayUnion({ name: file.name, url: downloadURL }),
          })
            .then(() => {
              console.log("Firestore updated with image metadata");
            })
            .catch((error) => {
              console.error("Error updating Firestore: ", error);
            });
        });
      }
    );
  };

  return (
    <>
      <div className="feedItem">
        <div className="itemChips">
          <Chip
            label={`${item.userName} • ${
              status === "Upcoming"
                ? "In " + formatDistanceStrict(dateToCompare, new Date())
                : status === "In Progress"
                ? "Now"
                : formatDistanceStrict(new Date(), dateToCompare) + " ago"
            }`}
            className="userChip"
            sx={{
              color: "white",
              backgroundColor: "#1bb6ca",
              fontSize: "1.2rem",
              zIndex: "0",
            }}
          />
          <div>
            {user && user.userId === item.userRef && (
              <>
                <Chip
                  label="Edit"
                  className="userChip"
                  sx={{
                    color: "white",
                    backgroundColor: "#1bb6ca",
                    fontSize: "1.2rem",
                    zIndex: "0",
                    margin: "0 10px",
                  }}
                  onClick={() => handleClickOpen(item)}
                />
                <Chip
                  label="x"
                  className="userChip"
                  sx={{
                    color: "white",
                    backgroundColor: "#1bb6ca",
                    fontSize: "1.2rem",
                    zIndex: "0",
                  }}
                  onClick={handleDelete}
                />
              </>
            )}
          </div>
        </div>
        <Paper elevation={24} className="hand-drawn-rectangle">
          <div className="topHalf">
            <div className="itemInfo">
              <LocationOnIcon className="itemIcon" />
              <h2 className="itemHeader">{item.category}</h2>
              <ReactCountryFlag
                countryCode={item.countryCode}
                svg
                style={{
                  width: "10%",
                  height: "10%",
                  marginTop: "10px",
                  border: "1px solid #1bb6ca",
                  borderRadius: "5px",
                }}
                title={item.country}
              />
            </div>
            <div className="itemTop">
              <img className="itemImage" src={item.imageUrl} alt="" />
              <h2 className="itemName">{item.name}</h2>
              <address className="itemAddress">{item.address}</address>
            </div>
            <div
              className="itemCheckIn"
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                margin: "10",
              }}
            >
              {startDate && (
                <time className="time">
                  <span className="heading">Date: </span>
                  <span>
                    {startDate instanceof Timestamp
                      ? startDate.toDate().toLocaleDateString()
                      : new Date(startDate).toLocaleDateString()}
                  </span>
                </time>
              )}
              {endDate && endDate !== "" && (
                <time className="time">
                  <span className="heading">Until:</span>
                  <span>
                    {endDate instanceof Timestamp
                      ? endDate.toDate().toLocaleDateString()
                      : new Date(endDate).toLocaleDateString()}
                  </span>
                </time>
              )}
            </div>
          </div>
          <Box
            sx={{
              width: "98%",
              marginLeft: "auto",
              marginRight: "auto",
              marginBottom: "10px",
              marginTop: "10px",
            }}
          >
            <Accordion
              sx={{
                border: "none",
                boxShadow: "none",
                width: "98%",
                marginLeft: "auto",
                marginRight: "auto",
                marginBottom: "10px",
                marginTop: "10px",
              }}
            >
              <StyledAccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <h2 className="itemExpand">View Experience</h2>
              </StyledAccordionSummary>
              <StyledAccordianDetails>
                <div className="itemBody">
                  <hr />
                  {item.review && (
                    <div className="itemBody">
                      <h2 className="itemHeader" style={{ marginLeft: "10px" }}>
                        About
                      </h2>
                      <div className="reviewSection">{review}</div>
                    </div>
                  )}
                  <hr />
                  <div className="experienceHeader">
                    <h2 className="itemHeader">Videos</h2>
                    {user && user.userId === item.userRef && (
                      <Chip
                        label="Add"
                        className="userChip"
                        sx={{
                          color: "white",
                          backgroundColor: "#1bb6ca",
                          fontSize: "1rem",
                          zIndex: "0",
                        }}
                        onClick={() => setIsModalOpen(true)}
                      />
                    )}
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "start",
                      marginLeft: "auto",
                      marginRight: "auto",
                      width: "90%",
                      flexWrap: "wrap",
                    }}
                  >
                    {experiences && experiences.length > 0 ? (
                      experiences.map((experience, index) => (
                        <div className="chipContainer" key={index}>
                          <Experience data={experience} />
                          {user && user.userId === item.userRef && (
                            <Chip
                              label="x"
                              className="userChip"
                              sx={{
                                color: "black",
                                fontSize: "1rem",
                                zIndex: "0",
                              }}
                              onClick={() => handleExperienceDelete(index)}
                            />
                          )}
                        </div>
                      ))
                    ) : (
                      <p>No videos yet.</p>
                    )}
                  </div>
                  <hr />
                </div>
                <div className="itemBody">
                  <div className="experienceHeader">
                    <h2 className="itemHeader">Photos</h2>
                    {user && user.userId === item.userRef && (
                      <Chip
                        label="Add"
                        className="userChip"
                        sx={{
                          color: "white",
                          backgroundColor: "#1bb6ca",
                          fontSize: "1rem",
                          zIndex: "0",
                        }}
                        onClick={() => setIsPhotoModalOpen(true)}
                      />
                    )}
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "start",
                      marginLeft: "auto",
                      marginRight: "auto",
                      width: "90%",
                      flexWrap: "wrap",
                    }}
                  >
                    {item.photos && item.photos.length > 0 ? (
                      <div className="chipContainer">
                        <ImageList variant="masonry" cols={2} gap={8}>
                          {item.photos.map((photo) => (
                            <ImageListItem key={photo.img}>
                              {user && user.userId === item.userRef && (
                                <Chip
                                  label="x"
                                  className={`userChip userChipNoPadding`}
                                  sx={{
                                    color: "#1bb6ca",
                                    backgroundColor: "white",
                                    fontSize: "1.2rem",
                                    zIndex: 2, // Ensure it's above the image
                                    width: "40px",
                                    height: "40px",
                                    padding: "0 !important",
                                  }}
                                  onClick={() => handleDeletePhoto(photo.name)}
                                />
                              )}
                              <img
                                srcSet={`${photo.url}?w=300&h=300&fit=crop&auto=format&dpr=2 2x`}
                                src={`${photo.url}?w=300&h=300&fit=crop&auto=format`}
                                alt={photo.name}
                                loading="lazy"
                                style={{
                                  cursor: "pointer",
                                  width: "100%",
                                  height: "auto",
                                }}
                                onClick={() => handlePhotoClick(photo.url)}
                              />
                            </ImageListItem>
                          ))}
                        </ImageList>
                      </div>
                    ) : (
                      <p>No photos yet.</p>
                    )}
                  </div>
                </div>
                <hr />
                {item.review && (
                  <div className="itemBody">
                    <h2 className="itemHeader" style={{ marginLeft: "10px" }}>
                      About
                    </h2>
                    <div className="reviewSection">{review}</div>
                  </div>
                )}
                <div className="ratings">
                  <span className="heading">{item.userName}'s rating: </span>
                  <Rating className="ratings" value={rating} readOnly />
                </div>
                <hr />
                <div className="itemBody">
                  <h2 className="itemHeader" style={{ marginLeft: "10px" }}>
                    {item.userName}
                  </h2>
                  <h3 className="statRank">
                    Rank: {userData && userData.stats && userData.stats.rank}
                  </h3>
                  <div></div>
                  <div className="statSection">
                    <div className="statItem">
                      {item.country} Rank:{" "}
                      {userData &&
                        userData.stats &&
                        userData.stats.locations &&
                        userData.stats.locations[item.country] &&
                        userData.stats.locations[item.country].rank}
                    </div>
                    <div className="statItem">
                      {item.state &&
                        item.state !== "N/A" && // Check if the state is not "N/A"
                        userData &&
                        userData.stats &&
                        userData.stats.locations &&
                        userData.stats.locations[item.country] &&
                        userData.stats.locations[item.country].states &&
                        userData.stats.locations[item.country].states[
                          item.state
                        ] && (
                          <>
                            {item.state} Rank:{" "}
                            {
                              userData.stats.locations[item.country].states[
                                item.state
                              ].rank
                            }
                            <br />
                          </>
                        )}
                    </div>
                    <div className="statItem">
                      {item.city &&
                        item.city !== "N/A" && // Check if the city is not "N/A"
                        item.country !== item.city && // Check if the country and city are not the same
                        userData &&
                        userData.stats &&
                        userData.stats.locations &&
                        userData.stats.locations[item.country] &&
                        userData.stats.locations[item.country].states &&
                        userData.stats.locations[item.country].states[
                          item.state
                        ] &&
                        userData.stats.locations[item.country].states[
                          item.state
                        ].cities &&
                        userData.stats.locations[item.country].states[
                          item.state
                        ].cities[item.city] && (
                          <>
                            {item.city} Rank:{" "}
                            {
                              userData.stats.locations[item.country].states[
                                item.state
                              ].cities[item.city].rank
                            }
                            <br />
                          </>
                        )}
                    </div>
                    <div className="itemCountriesVisited">
                      {userData &&
                        userData.stats &&
                        userData.stats.locations && (
                          <div>
                            Countries visited:
                            {Object.keys(userData.stats.locations).length}
                            <br />
                            {Object.keys(userData.stats.locations).map(
                              (country) => {
                                const countryCode =
                                  userData.stats.locations[country].countryCode;
                                return (
                                  <div
                                    style={{
                                      display: "inline-flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                      border: "1px solid #1bb6ca",
                                      marginRight: "5px",
                                    }}
                                  >
                                    <ReactCountryFlag
                                      countryCode={countryCode}
                                      svg
                                      style={{
                                        width: "3em",
                                        height: "auto",
                                      }}
                                      title={country}
                                    />
                                  </div>
                                );
                              }
                            )}
                          </div>
                        )}
                    </div>
                    <div className="itemSocials">
                      {userData && userData.facebook && (
                        <IconButton href={userData.facebook} target="_blank">
                          <img
                            src={facebook}
                            alt="Facebook"
                            className="iconImage"
                          />
                        </IconButton>
                      )}
                      {userData && userData.instagram && (
                        <IconButton href={userData.instagram} target="_blank">
                          <img
                            src={instagram}
                            alt="Instagram"
                            className="iconImage"
                          />
                        </IconButton>
                      )}
                      {userData && userData.tiktok && (
                        <IconButton href={userData.tiktok} target="_blank">
                          <img
                            src={tiktok}
                            alt="TikTok"
                            className="iconImage"
                          />
                        </IconButton>
                      )}
                      {userData && userData.x && (
                        <IconButton href={userData.x} target="_blank">
                          <img src={x} alt="X" className="iconImage" />
                        </IconButton>
                      )}
                      {userData && userData.youtube && (
                        <IconButton href={userData.youtube} target="_blank">
                          <img
                            src={youtube}
                            alt="YouTube"
                            className="iconImage"
                          />
                        </IconButton>
                      )}
                    </div>
                  </div>
                </div>
                {item.journey && (
                  <div className="ratings">
                    <Link to={`/journey/${item.journey.id}`}>
                      <Chip
                        label="Click here to view the full Journey"
                        className="userChip"
                        sx={{
                          color: "white",
                          backgroundColor: "#1bb6ca",
                          fontSize: "1rem",
                          zIndex: "0",
                        }}
                      />
                    </Link>
                  </div>
                )}
              </StyledAccordianDetails>
            </Accordion>
          </Box>
          {item.klookAdId && (
            <Box
              sx={{
                width: "98%",
                marginLeft: "auto",
                marginRight: "auto",
                marginBottom: "10px",
                marginTop: "10px",
              }}
            >
              <Accordion
                sx={{
                  border: "none",
                  boxShadow: "none",
                  width: "98%",
                  marginLeft: "auto",
                  marginRight: "auto",
                  marginBottom: "10px",
                  marginTop: "10px",
                }}
              >
                <StyledAccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <h2 className="itemExpand">Book</h2>
                </StyledAccordionSummary>
                <StyledAccordianDetails>
                  <ins
                    class="klk-aff-widget"
                    data-adid={item.klookAdId}
                    data-lang=""
                    data-currency=""
                    data-cardH="126"
                    data-padding="92"
                    data-lgH="470"
                    data-edgeValue="655"
                    data-prod="static_widget"
                    data-amount="3"
                  >
                    <a href="//www.klook.com/">Klook.com</a>
                  </ins>
                </StyledAccordianDetails>
              </Accordion>
            </Box>
          )}
        </Paper>
        <div className="itemActions">
          <div className="itemButtons">
            <IconButton aria-label="like" onClick={handleLike}>
              {liked ? (
                <FavoriteIcon style={{ fill: "#1bb6ca" }} />
              ) : (
                <FavoriteBorderIcon style={{ color: "#1bb6ca" }} />
              )}
            </IconButton>
            {item && item.likes && item.likes.length > 0 && (
              <span style={{ fontSize: "1rem" }}>{item.likes.length}</span>
            )}
            {/*
            <IconButton aria-label="share">
              <ShareIcon />
            </IconButton>
            <IconButton
              aria-label="comment"
              onClick={() => setShowComments(!showComments)}
            >
              <CommentIcon />
            </IconButton>
            */}
          </div>
          {/*}  {showComments && (
              <div className="commentSection">
                <CommentSection
                  currentUser={{
                    currentUserId: user ? user.uid : null,
                    currentUserFullName: user ? user.userName : "Guest",
                    currentUserImg:
                      user && user.userName
                        ? `https://ui-avatars.com/api/?name=${encodeURIComponent(
                            user.userName
                          )}&background=random`
                        : `https://ui-avatars.com/api/?name=Guest&background=random`,
                  }}
                  logIn={{
                    loginLink: () => Swal.fire("Please sign in to continue."),
                    signupLink: () => Swal.fire("Please sign in to continue."),
                  }}
                  commentData={comments.map((comment) => ({
                    userId: comment.authorId,
                    comId: comment.id,
                    fullName: comment.authorName,
                    userProfile: "", // You'll need to fetch this from your database
                    text: comment.text,
                    replies: [], // You'll need to fetch this from your database
                  }))}
                  onSubmitAction={async (data, parentId) => {
                    console.log("check submit, ", data);
                    console.log(parentId);
                    try {
                      let docRef;
                      if (parentId) {
                        console.log(parentId);
                        // This is a reply to a comment
                        const commentRef = doc(db, "Comments", parentId.comId);
                        const reply = {
                          authorId: user.uid,
                          authorName: user.userName,
                          text: data.text,
                          // Add any other fields you need
                        };
                        docRef = await addDoc(
                          collection(commentRef, "Replies"),
                          reply
                        );
                      } else {
                        // This is a new comment
                        const commentData = {
                          authorId: user.uid,
                          authorName: user.userName,
                          text: data.text,
                          hotelItemId: item.id,
                          // Add any other fields you need
                        };
                        docRef = await addDoc(
                          collection(db, "Comments"),
                          commentData
                        );
                        await updateDoc(docRef, { comId: docRef.id }); // Update the document to include the comId
                      }
                      console.log("Document written with ID: ", docRef.id);
                    } catch (e) {
                      console.error("Error adding document: ", e);
                    }
                  }}
                  currentData={(data) => {
                    console.log("current data", data);
                    // Here you can handle the current data, e.g. update your local state
                  }}
                />
              </div>
            )} */}
        </div>
      </div>
      <EditDialog item={currentItem} open={open} handleClose={handleClose} />
      <Dialog
        open={isPhotoModalOpen}
        onClose={() => setIsPhotoModalOpen(false)}
      >
        <DialogTitle>Add Photo</DialogTitle>
        <DialogContent>
          <FormControl>
            <FilePond
              files={files}
              allowMultiple={true}
              maxFileSize="5MB" // Set the maximum file size to 5MB
              acceptedFileTypes={["image/jpeg", "image/png"]} // Limit file types to JPEG and PNG
              onprocessfile={(error, file) => {
                console.log("File processed", file.serverId);
              }}
              server={{ process: handleProcess }}
              onupdatefiles={setFiles}
              labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
              labelMaxFileSizeExceeded="File is too large" // Custom message for files that exceed the size limit
              labelMaxFileSize="Maximum file size is 5MB" // Message showing the max file size
              labelFileTypeNotAllowed="Invalid file type" // Custom message for disallowed file types
              fileValidateTypeLabelExpectedTypes="Expects JPEG or PNG" // Message showing the expected file types
            />
            <FormHelperText>
              Select a photo to upload to this item's gallery
            </FormHelperText>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setIsPhotoModalOpen(false); // Close the modal
              setFiles([]); // Clear the files state
            }}
          >
            Complete
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <DialogTitle>Add Experience</DialogTitle>
        <DialogContent>
          <FormControl>
            <InputLabel id="experience-label">Experience</InputLabel>
            <Select
              labelId="experience-label"
              name="experience"
              value={newExperience.experience}
              onChange={handleNewExperienceChange}
              renderValue={(selected) => {
                if (selected === "") {
                  return <em>Select an experience</em>;
                } else {
                  return (
                    experiencePrompts[selected]?.name ||
                    newExperience.customExperience
                  );
                }
              }}
              className="select"
            >
              {experiencePrompts.map((experience, index) => (
                <MenuItem key={index} value={index}>
                  {experience.name}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText>
              Select an experience from the list or add your own
            </FormHelperText>
            <TextField
              name="customExperience"
              label="Custom Experience"
              fullWidth
              value={newExperience.customExperience || ""}
              onChange={handleNewExperienceChange}
            />
            <Typography className="inputLabel" style={{ marginTop: "10px" }}>
              About Experience
            </Typography>
            <Typography variant="body1">
              {experiencePrompts[newExperience.experience]?.description}
            </Typography>
            <Box mt={2}>
              <Typography className="inputLabel" style={{ marginTop: "10px" }}>
                Video Link
              </Typography>
              <FormControl>
                <Controller
                  name="newExperience.videoLink"
                  control={control}
                  defaultValue=""
                  rules={{
                    validate: validateVideoLink(
                      newExperience.experience || newExperience.customExperience
                    ),
                  }}
                  render={({ field }) => (
                    <TextField {...field} label="Video Link" fullWidth />
                  )}
                />
                <FormHelperText>
                  Enter the link to your YouTube, Instagram or TikTok video
                </FormHelperText>
              </FormControl>
            </Box>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsModalOpen(false)}>Cancel</Button>
          <Button onClick={handleNewExperienceSubmit}>Add</Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        aria-labelledby="full-size-photo-dialog"
        maxWidth="md"
        fullWidth
      >
        <IconButton
          aria-label="close"
          onClick={() => setOpenDialog(false)}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        {selectedPhotoUrl && (
          <img
            src={selectedPhotoUrl}
            alt="Full Size"
            style={{ width: "100%", height: "auto" }}
          />
        )}
      </Dialog>
    </>
  );
};

export default DestinationItem;
