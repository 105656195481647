/*eslint-disable*/
import React from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import zIndex from "@mui/material/styles/zIndex";

export default function Footer(props) {
  return (
    <footer style={footerStyle}>
      <div style={containerStyle}>
        <List style={listStyle}>
          <ListItem style={itemStyle}>
            <a
              href="mailto:jamie@kaleidotravelvlogs.com"
              target="_blank"
              style={linkStyle}
            >
              Contact
            </a>
          </ListItem>
          <ListItem style={itemStyle}>
            <a href="/legal" style={linkStyle}>
              Legal
            </a>
          </ListItem>
          <ListItem style={itemStyle}>
            <a
              href="https://discord.com/channels/kaleidotravel3749" // Replace with your Discord channel URL
              target="_blank"
              style={linkStyle}
            >
              Discord
            </a>
          </ListItem>
          {/* Add more list items here as needed */}
        </List>
        <div style={copyrightStyle}>
          &copy; {1900 + new Date().getYear()} Kaleido Travel{" "}
        </div>
        <div style={mobileTag}>| We ♥ Travelling </div>
      </div>
    </footer>
  );
}

const footerStyle = {
  position: "fixed",
  bottom: 0,
  width: "100vw",
  backgroundColor: "#1bb6ca",
  color: "#ffff",
  padding: "10px 0",
  zIndex: "1",
};

const containerStyle = {
  display: "flex",
  flexDirection: "row", // Horizontal layout on larger screens
  justifyContent: "space-between",
  alignItems: "center",
  maxWidth: "1200px",
  margin: "0 auto",
  padding: "0 10px", // Adding padding to prevent content from touching the edges
  "@media (max-width: 768px)": {
    flexDirection: "column", // Vertical layout on smaller screens
  },
  zIndex: "1000 !important",
};

const listStyle = {
  display: "flex",
  margin: 0,
  padding: 0,
  "@media (max-width: 768px)": {
    justifyContent: "center", // Centering on smaller screens
  },
};

const mobileTag = {
  display: "none",
};

const itemStyle = {
  padding: "5px",
};

const linkStyle = {
  color: "#fff",
  textDecoration: "none",
};

const copyrightStyle = {
  fontSize: "14px",
  color: "#fff",
  textAlign: "center", // Center-align on all screens
};

const iconStyle = {
  color: "#fff",
};
