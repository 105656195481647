import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import PersonIcon from "@mui/icons-material/Person";
import ShareIcon from "@mui/icons-material/Share";
import DirectionsBoatIcon from "@mui/icons-material/DirectionsBoat";
import { Paper } from "@mui/material";
import Chip from "@mui/material/Chip";
import ReactCountryFlag from "react-country-flag";
import { IconButton } from "@mui/material";
import { fromUnixTime, format } from "date-fns";
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import Button from "@mui/material/Button";
import Swal from "sweetalert2";
import "./item.css";

const CruiseJourneyItemCategory = ({ item, userId, onItemUpdate }) => {
  const [imageUrl, setImageUrl] = useState("");

  useEffect(() => {
    const fetchImageUrl = async () => {
      const storage = getStorage();
      const imageRef = ref(storage, `cruiseImages/${item.cruise.image}`);
      try {
        const url = await getDownloadURL(imageRef);
        setImageUrl(url);
      } catch (error) {
        console.error("Error fetching image URL: ", error);
      }
    };

    fetchImageUrl();
  }, [item.cruise.image]);

  return (
    <>
      <Link to={`/cruise/${item.objectID}`} style={{ textDecoration: "none" }}>
        <div className="feedItem">
          <Paper elevation={24} className="hand-drawn-rectangle">
            <div className="topHalf">
              <div className="itemInfo">
                <DirectionsBoatIcon className="itemIcon" />
                <h2 className="itemHeader">Cruise</h2>
                <DirectionsBoatIcon className="itemIcon" />
              </div>
              <div className="itemTop">
                <img src={imageUrl} alt="cruise" className="itemImage" />
                <h2 className="itemName">{item.cruise.name}</h2>
                <p className="journeyItemLocaion">{item.ship.name}</p>
                <p
                  className="itemDate"
                  style={{ fontWeight: "bold", color: "#415063" }}
                >
                  Departing from {item.departurePort}
                </p>
                {item.departureDate && (
                  <p className="itemDate">
                    {item.departureDate && (
                      <p className="itemDate">
                        {format(
                          fromUnixTime(item.departureDate / 1000),
                          "do 'of' MMMM yyyy"
                        )}
                        {item.endDate &&
                          ` to ${format(
                            fromUnixTime(item.endDate / 1000),
                            "do 'of' MMMM yyyy"
                          )}`}
                      </p>
                    )}
                  </p>
                )}
                <p style={{ fontSize: "1rem", fontWeight: "bold" }}>
                  {item.users
                    ? `${item.users.length} ${
                        item.users.length === 1 ? "cruiser" : "cruisers"
                      }`
                    : "0 cruisers"}
                </p>
              </div>
            </div>
            <div className="itemBody"></div>
          </Paper>
          <div className="itemActions">
            <div className="itemButtons">
              <IconButton aria-label="share">
                <ShareIcon />
              </IconButton>
            </div>
          </div>
        </div>
      </Link>
    </>
  );
};

export default CruiseJourneyItemCategory;
