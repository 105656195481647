import React, {
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
  useContext,
} from "react";
import Navbar from "../../Components/Navbar/Navbar";
import Footer from "../../Components/footer/footer.js";
import JourneyItem from "../../Components/homeFeed/homeFeed";
import Journey from "./Journey.js";
import { initializeApp } from "firebase/app";
import {
  getFirestore,
  collection,
  getDocs,
  doc,
  getDoc,
} from "firebase/firestore";
import { db } from "../../firebase.js";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Map from "./journeyItineraryMap.js";
import Modal from "@mui/material/Modal";
import { BeatLoader } from "react-spinners";
import Button from "@mui/material/Button";
import JourneyForm from "../../Components/items/newItem.js";
import UserContext from "../../UserContext";
import { css } from "@emotion/react";
import { Link, useParams } from "react-router-dom";
import "./JourneyPage.css";
console.log(db);

function JourneyPage() {
  const { id } = useParams();
  const [journey, setJourney] = useState(null);
  const [journeyItems, setJourneyItems] = useState([]);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [refresh, setRefresh] = useState(false);
  const user = useContext(UserContext);
  console.log(user);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const fetchJourney = async () => {
      setLoading(true);
      const journeyDoc = doc(db, "Journeys", id);
      const journeyData = await getDoc(journeyDoc);

      if (journeyData.exists()) {
        setJourney({ id: journeyData.id, ...journeyData.data() }); // Include the document ID in the state
      } else {
        console.log("No such document!");
      }
      setLoading(false);
    };

    fetchJourney();
  }, [id]);

  return (
    <div className="App">
      <Navbar />
      <Map items={journey} />
      <header className="App-header">
        <div className="tagLine">
          <p className="homeText">Kaleido</p>
          <p className="highlight"> Travel</p>
        </div>
        <div>
          <p className="heroP">
            Your interactive diary for sharing your journeys and inspiring
            perfect travel experiences for our community.
          </p>
          <div>
            <Button
              variant="contained"
              component={Link}
              to="/"
              sx={{ backgroundColor: "#1bb6ca" }}
            >
              Back to Home
            </Button>
            <Modal
              open={open}
              onClose={handleClose}
              style={{
                display: "flex",
                justifyContent: "center",
                overflow: "auto",
              }}
            >
              <JourneyForm
                user={user ? user : null}
                onClose={handleClose}
                setRefresh={setRefresh} // This will be used to refresh the feed after a new item is added
              />
            </Modal>
          </div>
        </div>

        {loading ? (
          <div style={{ marginTop: "20px" }}>
            <BeatLoader color="#123abc" loading={loading} size={15} />
          </div>
        ) : (
          <Journey
            userId={user ? user.uid : null}
            setItems={setItems}
            items={items}
            refresh={refresh} // This will be used to refresh the feed after a new item is added
            setRefresh={setRefresh}
            journey={journey}
          />
        )}
      </header>
      <Footer />
    </div>
  );
}

export default JourneyPage;
