import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import { Route, BrowserRouter, Routes } from "react-router-dom";
import HomePage from "./Pages/HomePage/HomePage";
import JourneyPage from "./Pages/JourneyPage/JourneyPage";
import CruisePage from "./Pages/CruisePage/CruisePage";
import RoadTripPage from "./Pages/RoadTripPage/RoadTripPage";
import Legal from "./Components/legal/legal";
import CruiseJourneyPage from "./Pages/CruisePage/CruiseJourneyPage";
import CruiseUploadPage from "./Pages/Admin/CruiseUpload";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { auth, db } from "./firebase";
import { doc, getDoc } from "firebase/firestore";
import UserContext from "./UserContext";

function App() {
  const [userDetails, setUserDetails] = useState(null);

  useEffect(() => {
    const unregisterAuthObserver = auth.onAuthStateChanged(
      async (firebaseUser) => {
        if (firebaseUser) {
          console.log("User is signed in:", firebaseUser); // Log when a user is signed in
          // User is signed in, so we get the user details from Firestore.
          const userDoc = doc(db, "Users", firebaseUser.uid);
          const userDocSnapshot = await getDoc(userDoc);
          if (userDocSnapshot.exists()) {
            setUserDetails(userDocSnapshot.data());
          } else {
            // Handle the case where the user document does not exist.
            console.error(
              `No document found for user with ID: ${firebaseUser.uid}`
            );
          }
        } else {
          console.log("No user is signed in."); // Log when no user is signed in
          // User is signed out.
          setUserDetails(null);
        }
      }
    );

    return unregisterAuthObserver; // Make sure we un-register Firebase observers when the component unmounts.
  }, []);

  useEffect(() => {
    console.log(userDetails);
  }, [userDetails]); // This useEffect will run every time `userDetails` changes.

  return (
    <UserContext.Provider value={userDetails}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/journey/:id" element={<JourneyPage />} />
          <Route path="/cruise" element={<CruisePage />} />
          <Route path="/cruise/:id" element={<CruiseJourneyPage />} />
          <Route path="/roadtrip" element={<RoadTripPage />} />
          <Route path="/admin/cruise-upload" element={<CruiseUploadPage />} />
          <Route path="/legal" element={<Legal />} />
        </Routes>
      </BrowserRouter>
    </UserContext.Provider>
  );
}

ReactDOM.render(<App />, document.getElementById("root"));
