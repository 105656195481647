import React, {
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
  useContext,
} from "react";
import Navbar from "../../Components/Navbar/Navbar";
import Footer from "../../Components/footer/footer.js";
import JourneyItem from "../../Components/homeFeed/homeFeed";
import { initializeApp } from "firebase/app";
import {
  getFirestore,
  collection,
  getDocs,
  doc,
  getDoc,
  updateDoc,
  arrayUnion,
  arrayRemove,
} from "firebase/firestore";
import { db } from "../../firebase.js";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Map from "./cruiseMap.js";
import Modal from "@mui/material/Modal";
import { BeatLoader } from "react-spinners";
import Button from "@mui/material/Button";
import JourneyForm from "../../Components/items/newCruiseItem.js";
import NewCruiseItinerary from "../../Components/items/newCruiseItinerary.js";
import UserContext from "../../UserContext";
import { css } from "@emotion/react";
import { Link, useParams } from "react-router-dom";
import { fromUnixTime, format } from "date-fns";
import ShareIcon from "@mui/icons-material/Share";
import DirectionsBoatIcon from "@mui/icons-material/DirectionsBoat";
import CruiseItem from "../../Components/items/cruiseItem.js";
import CruiseChat from "./CruiseChat.js";
import { Paper } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import ReactCountryFlag from "react-country-flag";
import algoliasearch from "algoliasearch";
import EditItinerary from "../../Components/items/editItinerary.js";
import "./CruisePage.css";
import Swal from "sweetalert2";

const firestore = getFirestore(); // Initialize Firestore

// Initialize Algolia client and index
const client = algoliasearch("1REB6WCMBC", "f95d9e3225c839f9b5fff98b732406db");
const index = client.initIndex("CruiseJourneys");
const cruiseItemsIndex = client.initIndex("CruiseJourneyItems");

function CruiseJourneyPage() {
  const { id } = useParams();
  const [item, setItem] = useState(null);
  const [journeyItems, setJourneyItems] = useState([]);
  const [open, setOpen] = useState(false);
  const [openItinerary, setOpenItinerary] = useState(false);
  const handleCloseItinerary = () => setOpenItinerary(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [editItinerary, setEditItinerary] = useState(false);
  const handleCloseEditItinerary = () => setEditItinerary(false);
  const [items, setItems] = useState([]);
  const [cruiseItem, setCruiseItem] = useState([]);
  const [loading, setLoading] = useState(true);
  const [refresh, setRefresh] = useState(false);
  const [imageUrl, setImageUrl] = useState("");
  const [isJoined, setIsJoined] = useState(false);
  const [cruiseItems, setCruiseItems] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const user = useContext(UserContext);
  console.log(user);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleSaveItinerary = async (updatedItinerary) => {
    try {
      await firestore.collection("cruises").doc(item.id).update({
        itinerary: updatedItinerary,
      });
      console.log("Itinerary updated successfully");
      setIsEditing(false);
    } catch (error) {
      console.error("Error updating itinerary: ", error);
    }
  };

  useEffect(() => {
    const fetchJourney = async () => {
      setLoading(true);
      try {
        const journeyDoc = await getDoc(doc(db, "CruiseJourneys", id));
        if (journeyDoc.exists()) {
          const journeyData = journeyDoc.data();
          setItem({ id: journeyDoc.id, ...journeyData });
          setCruiseItem({ id: journeyDoc.id, ...journeyData });
          if (journeyData.users) {
            const userExists = journeyData.users.some(
              (u) => u.uid === user.uid
            );
            setIsJoined(userExists);
          }
        } else {
          console.log("No such document!");
        }
      } catch (error) {
        console.log("Error fetching document: ", error);
      }
      setLoading(false);
    };

    fetchJourney();

    // Cleanup effect to reset isJoined when user changes
    return () => {
      setIsJoined(false);
    };
  }, [id, user]);

  useEffect(() => {
    if (item && item.cruise && item.cruise.image) {
      const fetchImageUrl = async () => {
        const storage = getStorage();
        const imageRef = ref(storage, `cruiseImages/${item.cruise.image}`);
        try {
          const url = await getDownloadURL(imageRef);
          setImageUrl(url);
        } catch (error) {
          console.error("Error fetching image URL: ", error);
        }
      };

      fetchImageUrl();
    }
  }, [item]);

  useEffect(() => {
    const fetchCruiseItems = async () => {
      if (item && item.id) {
        console.log("Fetching cruise items for item id:", item.id);
        try {
          console.log("Before cruiseItemsIndex.search call");
          const cruiseItemsContent = await cruiseItemsIndex.search("", {
            filters: `cruiseId:${item.id}`,
          });
          console.log("After cruiseItemsIndex.search call");
          console.log("Cruise items fetched:", cruiseItemsContent);
          setCruiseItems(cruiseItemsContent.hits);
        } catch (error) {
          console.error("Error fetching cruise items: ", error);
        }
      } else {
        console.log("Item or item.id is not set");
      }
    };

    fetchCruiseItems();
  }, [item]);

  console.log("Cruise items state:", cruiseItems);
  console.log(item);

  const handleJoin = async () => {
    if (!user) return;

    try {
      const journeyDocRef = doc(db, "CruiseJourneys", id);
      const journeyDoc = await getDoc(journeyDocRef);
      if (journeyDoc.exists()) {
        const journeyData = journeyDoc.data();
        const users = journeyData.users || [];
        console.log(journeyDoc.id);
        // Check if user is already in the list
        const userExists = users.some((u) => u.uid === user.uid);
        if (!userExists) {
          await updateDoc(journeyDocRef, {
            users: arrayUnion({ userName: user.userName, uid: user.uid }),
          });

          // Update the Users collection
          const userRef = doc(db, "Users", user.uid); // replace 'user.uid' with actual user id
          const userSnap = await getDoc(userRef);
          let userData = userSnap.data(); // Use a different variable name here

          // Initialize stats field if it doesn't exist
          if (!userData.stats) {
            userData.stats = {
              rank: 1, // Initialize rank
              points: 0, // Initialize overall points
            };
          } else {
            // Initialize rank field if it doesn't exist
            if (userData.stats.rank === undefined) {
              userData.stats.rank = 1; // Initialize rank
            }

            if (typeof userData.stats.points !== "number") {
              // If userData.stats.points exists but is not a number, reset it to 0
              userData.stats.points = 0;
            }
          }

          // Initialize category field within stats if it doesn't exist
          if (!userData.stats[item.category]) {
            userData.stats[item.category] = {
              totalSubmissions: 0,
              totalCruises: 0,
              uniqueSubmissions: {},
              uniqueCruises: {},
              points: 0,
              rank: 1, // Initialize rank for category
              cruiseline: {}, // Initialize cruiseline object
              cruiseship: {}, // Initialize cruiseship object
            };
          }

          const categoryStats = userData.stats[item.category];
          categoryStats.totalSubmissions += 1;
          categoryStats.totalCruises += 1;
          categoryStats.points += 10; // replace with actual points calculation
          userData.stats.points += 10; // Increment overall points

          // Update rank based on points
          userData.stats.rank = Math.floor(userData.stats.points / 100) + 1;

          // Update category rank based on category points
          categoryStats.rank = Math.floor(categoryStats.points / 50) + 1;

          // Ensure uniqueCruises is initialized
          if (!categoryStats.uniqueCruises) {
            categoryStats.uniqueCruises = {};
          }

          // Add unique cruise
          if (!categoryStats.uniqueCruises[journeyDoc.id]) {
            categoryStats.uniqueCruises[journeyDoc.id] = {
              cruiseline: item.cruise.name,
              cruiseship: item.ship.name,
              cruiseDate: item.departureDate,
              cruisePort: item.departurePort,
              id: item.id,
            };
          }

          //add count for how many times user has joined a cruise
          if (!categoryStats.cruiseline[item.cruise.id]) {
            categoryStats.cruiseline[item.cruise.id] = {
              cruiseline: item.cruise.name,
              count: 1,
            };
          } else {
            categoryStats.cruiseline[item.cruise.id].count += 1;
          }

          //add count for how many times user has joined a ship
          if (!categoryStats.cruiseship[item.ship.id]) {
            categoryStats.cruiseship[item.ship.id] = {
              cruiseship: item.ship.name,
              count: 1,
            };
          } else {
            categoryStats.cruiseship[item.ship.id].count += 1;
          }

          // Update user document in Firestore
          await updateDoc(userRef, userData);

          setItem({
            ...item,
            users: [...users, { userName: user.userName, uid: user.uid }],
          });
          setIsJoined(true);
          Swal.fire({
            title: "Joined!",
            text: "You have successfully joined the journey.",
            icon: "success",
            zIndex: 2000,
          });
        }
      }
    } catch (error) {
      console.error("Error updating users: ", error);
    }
  };

  const handleUnjoin = async () => {
    if (!user) return;
    try {
      const journeyDocRef = doc(db, "CruiseJourneys", id);
      const journeyDoc = await getDoc(journeyDocRef);
      if (journeyDoc.exists()) {
        const journeyData = journeyDoc.data();
        const users = journeyData.users || [];

        // Check if user is in the list
        const userExists = users.some((u) => u.uid === user.uid);
        if (userExists) {
          await updateDoc(journeyDocRef, {
            users: arrayRemove({ userName: user.userName, uid: user.uid }),
          });

          // Update the Users collection
          const userRef = doc(db, "Users", user.uid);
          const userSnap = await getDoc(userRef);
          let userData = userSnap.data();

          if (userData.stats && userData.stats[item.category]) {
            const categoryStats = userData.stats[item.category];
            console.log(categoryStats);

            // Decrement total submissions and points
            categoryStats.totalSubmissions -= 1;
            categoryStats.totalCruises -= 1;
            categoryStats.points -= 10; // replace with actual points calculation
            userData.stats.points -= 10; // Decrement overall points

            // Update rank based on points
            userData.stats.rank = Math.floor(userData.stats.points / 100) + 1;

            // Update category rank based on category points
            categoryStats.rank = Math.floor(categoryStats.points / 50) + 1;

            // Remove unique submission
            if (categoryStats.uniqueCruises[journeyDoc.id]) {
              console.log("Removing unique cruise");
              console.log(categoryStats.uniqueCruises[journeyDoc.id]);
              delete categoryStats.uniqueCruises[journeyDoc.id];
            }

            // Decrement count for how many times user has joined a cruise
            if (categoryStats.cruiseline[item.cruise.id]) {
              categoryStats.cruiseline[item.cruise.id].count -= 1;
              if (categoryStats.cruiseline[item.cruise.id].count === 0) {
                delete categoryStats.cruiseline[item.cruise.id];
              }
            }

            // Decrement count for how many times user has joined a ship
            if (categoryStats.cruiseship[item.ship.id]) {
              categoryStats.cruiseship[item.ship.id].count -= 1;
              if (categoryStats.cruiseship[item.ship.id].count === 0) {
                delete categoryStats.cruiseship[item.ship.id];
              }
            }

            // Update user document in Firestore
            await updateDoc(userRef, userData);
          }

          setItem({
            ...item,
            users: users.filter((u) => u.uid !== user.uid),
          });
          setIsJoined(false);
          Swal.fire({
            title: "Left!",
            text: "You have successfully left the journey.",
            icon: "success",
            zIndex: 2000,
          });
        }
      }
    } catch (error) {
      console.error("Error updating users: ", error);
    }
  };

  const handleAddActivity = async () => {
    if (!isJoined) {
      Swal.fire({
        icon: "info",
        title: "Please sign in",
        text: "You need to join this cruise to add an experience",
      });
    } else {
      setOpen(true);
    }
  };

  const handleAddItinerary = async () => {
    if (!isJoined) {
      Swal.fire({
        icon: "info",
        title: "Please sign in",
        text: "You need to join this cruise to add an itinerary",
      });
    } else {
      setOpenItinerary(true);
    }
  };

  const handleEditItinerary = async () => {
    if (!isJoined) {
      Swal.fire({
        icon: "info",
        title: "Please sign in",
        text: "You need to join this cruise to add an itinerary",
      });
    } else {
      setEditItinerary(true);
    }
  };

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  console.log(cruiseItems);
  console.log(isJoined);
  return (
    <div className="App">
      <Navbar />
      <Map items={item} />
      <header className="App-header">
        <div className="tagLine">
          <p className="homeText" style={{ marginRight: "5px" }}>
            Kaleido
          </p>
          <p className="highlight"> Cruise</p>
        </div>
        <div>
          <p className="heroP">
            The {item?.ship?.name} is setting sail on{" "}
            {item?.departureDate &&
              format(item.departureDate.toDate(), "do 'of' MMMM yyyy")}
            . Follow along to see the experiences shared by fellow cruisers.
          </p>
          <div>
            <div>
              <Button
                variant="contained"
                component={Link}
                to="/Cruise"
                sx={{ backgroundColor: "#1bb6ca" }}
              >
                Back to Cruises
              </Button>
              <Button
                variant="contained"
                component={Link}
                to="/"
                sx={{ backgroundColor: "#1bb6ca", margin: "10px" }}
              >
                Back to Home
              </Button>
            </div>
            <Modal
              open={open}
              onClose={handleClose}
              style={{
                display: "flex",
                justifyContent: "center",
                overflow: "auto",
              }}
            >
              <JourneyForm
                user={user ? user : null}
                onClose={handleClose}
                setRefresh={setRefresh} // This will be used to refresh the feed after a new item is added
                item={item}
              />
            </Modal>
            <Modal
              open={openItinerary}
              onClose={handleCloseItinerary}
              style={{
                display: "flex",
                justifyContent: "center",
                overflow: "auto",
              }}
            >
              <NewCruiseItinerary
                user={user ? user : null}
                onClose={handleCloseItinerary}
                setRefresh={setRefresh} // This will be used to refresh the feed after a new item is added
                item={item}
              />
            </Modal>
            <Modal
              open={editItinerary}
              onClose={handleCloseEditItinerary}
              style={{
                display: "flex",
                justifyContent: "center",
                overflow: "auto",
              }}
            >
              <EditItinerary
                user={user ? user : null}
                onClose={handleCloseEditItinerary}
                setRefresh={setRefresh} // This will be used to refresh the feed after a new item is added
                item={item}
              />
            </Modal>
          </div>
        </div>

        {loading ? (
          <div style={{ marginTop: "20px" }}>
            <BeatLoader color="#123abc" loading={loading} size={15} />
          </div>
        ) : (
          <div className="feedItem">
            <Paper elevation={24} className="hand-drawn-rectangle">
              <div className="topHalf">
                <div className="itemInfo">
                  <DirectionsBoatIcon className="itemIcon" />
                  <h2 className="itemHeader">Cruise</h2>
                  <DirectionsBoatIcon className="itemIcon" />
                </div>
                <div className="itemTop">
                  <img src={imageUrl} alt="cruise" className="itemImage" />
                  <h2 className="itemName">{item.cruise.name}</h2>
                  <p className="journeyItemLocaion">{item.ship.name}</p>
                  {item.departureDate && (
                    <p className="itemDate">
                      {format(item.departureDate.toDate(), "do 'of' MMMM yyyy")}
                      {item.endDate &&
                        ` to ${format(
                          item.endDate.toDate(),
                          "do 'of' MMMM yyyy"
                        )}`}
                    </p>
                  )}
                  {item.itinerary &&
                  item.itinerary.days &&
                  item.itinerary.days.length > 0 ? (
                    <div style={{ width: "100%" }}>
                      {isEditing ? (
                        <EditItinerary
                          itinerary={item.itinerary}
                          onSave={handleSaveItinerary}
                        />
                      ) : (
                        <>
                          <hr />
                          <div className="itemItinerary">
                            <h2
                              className="itemName"
                              style={{
                                marginLeft: "auto",
                                marginRight: "auto",
                              }}
                            >
                              Itinerary
                            </h2>

                            {/* Render the itinerary days here */}
                            {item.itinerary.days.map((day, index) => (
                              <div
                                key={index}
                                style={{
                                  display: "flex",
                                  alignItems: "start",
                                  justifyContent: "start",
                                }}
                              >
                                <p className="itinerary">
                                  <p
                                    style={{
                                      fontWeight: "bold",
                                      marginRight: "5px",
                                      width: "90px",
                                    }}
                                  >
                                    Day {day.day}:
                                  </p>
                                  {day.type === "Sea Day" ? day.type : day.name}
                                  {day.type === "destination" && (
                                    <div
                                      style={{
                                        display: "inline-flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        border: "1px solid #1bb6ca",
                                        margin: "10px 5px",
                                      }}
                                    >
                                      <ReactCountryFlag
                                        countryCode={day.countryCode}
                                        svg
                                        style={{
                                          width: "3em",
                                          height: "auto",
                                        }}
                                        title={day.country}
                                      />
                                    </div>
                                  )}
                                  {day.type === "Sea Day" && (
                                    <DirectionsBoatIcon className="itemIcon" />
                                  )}
                                </p>
                              </div>
                            ))}
                          </div>
                          <hr />
                          <div>
                            <Button
                              variant="contained"
                              sx={{
                                backgroundColor: "#1bb6ca",
                                margin: "10px",
                              }}
                              onClick={handleEditItinerary}
                            >
                              Edit Itinerary
                            </Button>
                          </div>
                        </>
                      )}
                    </div>
                  ) : (
                    <>
                      <h2 className="itemHeader">Itinerary</h2>
                      <div className="itemItinerary">
                        <p>No itinerary added</p>
                        <div
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <Button
                            variant="contained"
                            sx={{ backgroundColor: "#1bb6ca", margin: "10px" }}
                            onClick={handleAddItinerary}
                          >
                            Add Itinerary
                          </Button>
                        </div>
                      </div>
                      <hr />
                    </>
                  )}
                  <Button
                    variant="contained"
                    sx={{ backgroundColor: "#1bb6ca", margin: "10px" }}
                    onClick={isJoined ? handleUnjoin : handleJoin}
                  >
                    {isJoined ? "Leave" : "Join"}
                  </Button>
                  <Button
                    variant="contained"
                    sx={{
                      backgroundColor: "transparent",
                      color: "#1bb6ca",
                      margin: "10px",
                      boxShadow: "none",
                    }}
                    onClick={handleExpandClick}
                  >
                    {expanded ? "- Hide" : "+ Show"}{" "}
                    {item.users
                      ? `${item.users.length} ${
                          item.users.length === 1 ? "cruiser" : "cruisers"
                        }`
                      : "0 cruisers"}
                  </Button>
                  {expanded && item.users && item.users.length > 0 && (
                    <ul>
                      {item.users.map((user, index) => (
                        <li style={{ fontSize: "1.2rem" }} key={index}>
                          {user.userName}
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
              </div>
              <div className="itemBody"></div>
            </Paper>
            <div className="itemActions">
              <div className="itemButtons">
                <IconButton aria-label="share">
                  <ShareIcon />
                </IconButton>
              </div>
            </div>
          </div>
        )}
        <CruiseChat item={item} user={user} />
        {loading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "start",
              marginTop: "2em",
              height: "100vh",
            }}
          >
            <BeatLoader color="#123abc" loading={loading} size={15} />
          </div>
        ) : (
          <>
            <div className="timeline-container">
              <Paper
                elevation={24}
                className="hand-drawn-rectangle"
                style={{ margin: "20px" }}
              >
                <div className="topHalf">
                  <h4
                    className="timeline-header"
                    style={{ fontSize: "1.2rem" }}
                  >
                    Add Your Experience
                  </h4>
                  <p style={{ fontSize: "1rem", marginBottom: "10px" }}>
                    To share your experience on this cruise, click the button
                    below. We are looking forward to sharing about your journey!
                  </p>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      width: "200px",
                      justifyContent: "center",
                      marginLeft: "auto",
                      marginRight: "auto",
                      marginBottom: "15px",
                    }}
                  >
                    <Button
                      variant="contained"
                      sx={{ backgroundColor: "#1bb6ca" }}
                      onClick={handleAddActivity}
                    >
                      Add Your Experience
                    </Button>
                  </div>
                </div>
              </Paper>
            </div>
            <div className="timeline-container">
              <Paper elevation={24} className="ExperiencesRectangle">
                <div className="topHalf">
                  <h4 className="timeline-header">Experiences</h4>
                </div>
              </Paper>
              {cruiseItems.map((item) => (
                <CruiseItem
                  key={item.id}
                  item={item}
                  cruiseItem={cruiseItem}
                  setRefresh={setRefresh}
                  user={user}
                />
              ))}
            </div>
          </>
        )}
      </header>

      <Footer />
    </div>
  );
}

export default CruiseJourneyPage;
