/* global google */
import React, { useState, useEffect, useContext } from "react";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { getFirestore } from "firebase/firestore";
import {
  collection,
  addDoc,
  getDocs,
  query,
  where,
  serverTimestamp,
  Timestamp,
  updateDoc,
  getDoc,
  doc,
} from "firebase/firestore";
import { db, GeoPoint } from "../../firebase";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { geohashForLocation } from "geofire-common";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import { geocodeByPlaceId } from "react-places-autocomplete";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  Grid,
  TextField,
  Rating,
  Typography,
  Button,
  Box,
  IconButton,
} from "@mui/material";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import Swal from "sweetalert2";
import axios from "axios";
import CircularProgress from "@mui/material/CircularProgress";
import NewHotelItem from "./newHotelItem";
import NewDestinationItem from "./newDestinationItem";
import NewActivityItem from "./newActivityItem";
import NewFoodItem from "./newFoodItem";
import NewThemeParkItem from "./newThemeParkItem";
import "./newItem.css";

function NewItem(props) {
  const { user, onClose, addNewItem, fetchData, setRefresh } = props;
  const { control } = useForm();
  const [category, setCategory] = useState("");

  const handleCategoryChange = (event) => {
    setCategory(event.target.value);
  };

  console.log(user);

  return (
    <div className="form-container">
      <div className="Itemform">
        <div className="formHeading">
          <h1 className="headingdark">Add a new </h1>
          <h1 className="headingLight">Experience</h1>
        </div>
        <Grid className="formControl" container direction="column" spacing={2}>
          {" "}
          <Grid item>
            {" "}
            {/* Wrap each form control in a Grid item */}
            <FormControl style={{ minWidth: 120 }}>
              <InputLabel id="category-label">Category</InputLabel>
              <Controller
                name="category"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <Select
                    labelId="category-label"
                    {...field}
                    onChange={(e) => {
                      field.onChange(e);
                      setCategory(e.target.value);
                    }}
                  >
                    <MenuItem value="Hotel">Hotel</MenuItem>
                    <MenuItem value="Destination">Destination</MenuItem>
                    <MenuItem value="Activity">Activity</MenuItem>
                    <MenuItem value="Food">Food</MenuItem>
                    <MenuItem value="ThemePark">Theme Park</MenuItem>
                    {/* Add more MenuItem components for additional categories */}
                  </Select>
                )}
              />
            </FormControl>
          </Grid>
          {/* add more options for other categories */}
          <hr />
          {category === "Hotel" && (
            <NewHotelItem
              user={user}
              onClose={onClose}
              category={category}
              setRefresh={setRefresh} // This will be used to refresh the feed after a new item is added
            />
          )}
          {category === "Destination" && (
            <NewDestinationItem
              user={user}
              onClose={onClose}
              category={category}
              setRefresh={setRefresh} // This will be used to refresh the feed after a new item is added
            />
          )}
          {category === "Activity" && (
            <NewActivityItem
              user={user}
              onClose={onClose}
              category={category}
              setRefresh={setRefresh} // This will be used to refresh the feed after a new item is added
            />
          )}
          {category === "Food" && (
            <NewFoodItem
              user={user}
              onClose={onClose}
              category={category}
              setRefresh={setRefresh} // This will be used to refresh the feed after a new item is added
            />
          )}
          {category === "ThemePark" && (
            <NewThemeParkItem
              user={user}
              onClose={onClose}
              category={category}
              setRefresh={setRefresh} // This will be used to refresh the feed after a new item is added
            />
          )}
        </Grid>
      </div>
    </div>
  );
}

export default NewItem;
