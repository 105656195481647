/* global google */
import React, { useState, useEffect, useRef } from "react";
import { FilePond, registerPlugin } from "react-filepond";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { getFirestore } from "firebase/firestore";
import {
  collection,
  addDoc,
  getDocs,
  query,
  where,
  serverTimestamp,
  Timestamp,
  updateDoc,
  getDoc,
  doc,
} from "firebase/firestore";
import { db, GeoPoint, storage } from "../../firebase";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { geohashForLocation } from "geofire-common";
import PlacesAutocomplete from "react-places-autocomplete";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  Grid,
  TextField,
  Rating,
  Typography,
  Box,
  IconButton,
} from "@mui/material";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import Swal from "sweetalert2";
import CircularProgress from "@mui/material/CircularProgress";
import "./newItem.css";
// Import the Image EXIF Orientation and Image Preview plugins
// Note: These need to be installed separately
// `npm i filepond-plugin-image-preview filepond-plugin-image-exif-orientation --save`
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import { ref as storageRef, uploadBytesResumable } from "firebase/storage";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";

// Register the plugins
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

function NewActivityItem({
  user,
  onClose,
  addNewItem,
  props,
  category,
  setRefresh,
}) {
  const [selectedActivity, setSelectedActivity] = useState(null);
  const [autocompleteValue, setAutocompleteValue] = useState("");
  const [journeys, setJourneys] = useState([]);
  const [selectedJourney, setSelectedJourney] = useState(null);
  const [loading, setLoading] = useState(false);
  const [experiencePrompts, setExperiencePrompts] = useState([]);
  const db = getFirestore();
  const storage = getStorage();
  const [uploadMetadata, setUploadMetadata] = useState([]);
  const [files, setFiles] = useState([]);
  const {
    control,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      experiences: [{ experience: "", videoLink: "" }],
      // other form fields...
    },
  });
  
  const handleProcess = (
    fieldName,
    file,
    metadata,
    load,
    error,
    progress,
    abort
  ) => {
    const filePath = `UserImages/${user.userName}/${file.name}`;
    const fileRef = storageRef(storage, filePath);
    const uploadTask = uploadBytesResumable(fileRef, file);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progressPercentage =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        progress(true, snapshot.bytesTransferred, snapshot.totalBytes);
        console.log("Upload is " + progressPercentage + "% done");
      },
      (err) => {
        error(err.message);
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          load(downloadURL);
          console.log("File available at", downloadURL);
          setUploadMetadata((prevMetadata) => [
            ...prevMetadata,
            { name: file.name, url: downloadURL },
          ]);
        });
      }
    );
  };

  const { fields, append, remove } = useFieldArray({
    control,
    name: "experiences",
  });

  // Fetch the user's journeys from Firestore
  useEffect(() => {
    setJourneys(user.journeys || []);
  }, [user.journeys]);

  useEffect(() => {
    const fetchExperiencePrompts = async () => {
      const experiencePromptsCollection = collection(
        db,
        "Experiences",
        "jfHCIMDg5Uj2PCkWxBEd",
        "Activities",
        "aD1MHHnFj4mKdpo6traJ",
        "ExperiencePrompts"
      );
      const experiencePromptsSnapshot = await getDocs(
        experiencePromptsCollection
      );
      const experiencePromptsData = experiencePromptsSnapshot.docs.map((doc) =>
        doc.data()
      );
      setExperiencePrompts(experiencePromptsData);
    };

    fetchExperiencePrompts();
  }, []);

  const createNewJourneyItem = async (journeyItem) => {
    console.log("Starting createNewJourneyItem function");
    console.log("selectedActivity:", selectedActivity);
    console.log(user);

    setLoading(true);

    try {
      if (selectedActivity !== null) {
        console.log("selectedActivity is not null");

        // Get the hotel data from the selectedHotel state
        const activityData = selectedActivity;

        // Query the Hotels collection for a hotel with the same name
        const q = query(
          collection(db, "Activities"),
          where("name", "==", activityData.name)
        );
        const querySnapshot = await getDocs(q);

        // Define hotelId before the if condition
        let activityId;

        if (querySnapshot.empty) {
          console.log("Activity does not exist in the Activites collection");

          let blob;

          // Get the place ID from the hotel data
          const placeId = activityData.placeId;

          if (typeof placeId === "string") {
            const functionUrl = `https://australia-southeast1-kaleido-travel-vlogs.cloudfunctions.net/getImage?placeId=${encodeURIComponent(
              placeId
            )}`;
            const response = await fetch(functionUrl);
            if (!response.ok) {
              throw new Error(`HTTP error! status: ${response.status}`);
            }
            const buffer = await response.arrayBuffer();
            blob = new Blob([buffer], { type: "image/jpeg" });
          } else {
            console.error("placeId is not a string or is undefined:", placeId);
          }

          if (blob) {
            // Upload the image to Firebase Storage
            const storage = getStorage();
            const storageRef = ref(
              storage,
              `activityImages/${activityData.name}`
            );
            const uploadSnapshot = await uploadBytes(storageRef, blob);
            console.log("Upload snapshot:", uploadSnapshot);

            // Get the download URL for the image
            const url = await getDownloadURL(storageRef);
            console.log("Download URL:", url);

            // Store the download URL in the hotelData
            activityData.imageUrl = url;

            // Add the activity to the Activitys collection
            const docRef = await addDoc(
              collection(db, "Activities"),
              activityData
            );
            console.log("Added new hotel with ID: ", docRef.id);
            // Assign the new hotel's ID to hotelId
            activityId = docRef.id;
          } else {
            console.log("Blob is not defined");
          }
        } else {
          // If the hotel does exist, use its data
          console.log("Found existing activity with data: ", activityData);

          // Get the first document from the querySnapshot
          const doc = querySnapshot.docs[0];

          // Get the data from the document
          const existingActivityData = doc.data();

          // Get the ID of the activity document
          activityId = doc.id;

          // Use the existing hotel's imageUrl
          activityData.imageUrl = existingActivityData.imageUrl;
        }

        // Add the activity data to the journeyItem
        journeyItem = { ...journeyItem, ...activityData };

        // Calculate the geohash for the location
        const geohash = geohashForLocation([
          activityData.geopoint.latitude,
          activityData.geopoint.longitude,
        ]);

        // Add the geohash to the journeyItem
        journeyItem.geohash = geohash;

        // Add the geopoint to the journeyItem
        journeyItem.geopoint = activityData.geopoint;

        // Add a createdAt field with the current timestamp
        journeyItem.createdAt = serverTimestamp();

        // Convert the startDate and endDate strings to Timestamps
        if (journeyItem.startDate) {
          journeyItem.startDate = Timestamp.fromDate(
            new Date(journeyItem.startDate)
          );
        }

        if (journeyItem.endDate) {
          journeyItem.endDate = Timestamp.fromDate(
            new Date(journeyItem.endDate)
          );
        }

        // Add the user details to the journeyItem
        console.log(user);
        journeyItem.userId = user.uid;
        journeyItem.userName = user.userName;

        // Add the selected journey ID and name to the journeyItem if a journey was selected
        if (selectedJourney !== null) {
          journeyItem.journeyId = selectedJourney.id;
          journeyItem.journeyName = selectedJourney.name;
        }

        // Add the journeyItem to the JourneyItems collection
        const journeyItemDocRef = await addDoc(
          collection(db, "JourneyItems"),
          journeyItem
        );

        // Update user stats
        const userRef = doc(db, "Users", user.uid); // replace 'user.uid' with actual user id
        const userSnap = await getDoc(userRef);
        let userData = userSnap.data(); // Use a different variable name here

        // Initialize stats field if it doesn't exist
        if (!userData.stats) {
          userData.stats = {
            rank: 1, // Initialize rank
            points: 0, // Initialize overall points
          };
        } else {
          // Initialize rank field if it doesn't exist
          if (userData.stats.rank === undefined) {
            userData.stats.rank = 1; // Initialize rank
          }

          if (typeof userData.stats.points !== "number") {
            // If userData.stats.points exists but is not a number, reset it to 0
            userData.stats.points = 0;
          }
        }

        // Initialize category field within stats if it doesn't exist
        if (!userData.stats[journeyItem.category]) {
          userData.stats[journeyItem.category] = {
            totalSubmissions: 0,
            uniqueSubmissions: {},
            points: 0,
            rank: 1, // Initialize rank for category
          };
        }

        const categoryStats = userData.stats[journeyItem.category];
        categoryStats.totalSubmissions += 1;
        categoryStats.points += 10; // replace with actual points calculation
        userData.stats.points += 10; // Increment overall points

        // Update rank based on points
        userData.stats.rank = Math.floor(userData.stats.points / 100) + 1;

        // Update category rank based on category points
        categoryStats.rank = Math.floor(categoryStats.points / 50) + 1;

        // Add unique submission
        if (!categoryStats.uniqueSubmissions[journeyItemDocRef.id]) {
          categoryStats.uniqueSubmissions[journeyItemDocRef.id] = {
            name: activityData.name,
            id: activityId, // Use the ID of the activity document
          };
        }

        // Initialize stats and locations field if they don't exist
        if (!userData.stats.locations) {
          userData.stats.locations = {};
        }

        // Get the location of the hotel
        const country = activityData.country;
        const countryCode = activityData.countryCode;
        const state = activityData.state;
        const city = activityData.city;

        // Initialize country if it doesn't exist
        if (!userData.stats.locations[country]) {
          userData.stats.locations[country] = {
            countryCode: countryCode, // Add the country code
            states: {},
            totalItems: 0,
            uniqueSubmissions: {},
            points: 0, // Initialize points for country
            rank: 1, // Initialize rank for country
          };
        }

        // Initialize state if it doesn't exist
        if (!userData.stats.locations[country].states[state]) {
          userData.stats.locations[country].states[state] = {
            cities: {},
            totalItems: 0,
            uniqueSubmissions: {},
            points: 0, // Initialize points for state
            rank: 1, // Initialize rank for state
          };
        }

        // Initialize city if it doesn't exist
        if (!userData.stats.locations[country].states[state].cities[city]) {
          userData.stats.locations[country].states[state].cities[city] = {
            totalItems: 0,
            uniqueSubmissions: {},
            points: 0, // Initialize points for city
            rank: 1, // Initialize rank for city
          };
        }

        // List of locations to update
        const locations = [
          userData.stats.locations[country],
          userData.stats.locations[country].states[state],
          userData.stats.locations[country].states[state].cities[city],
        ];

        // Update each location
        for (const location of locations) {
          // Increment total items count
          location.totalItems += 1;

          // Add unique submission
          location.uniqueSubmissions[journeyItemDocRef.id] = {
            name: activityData.name,
            id: activityId, // Use the ID of the hotel document
          };

          // Increment points for location
          location.points += 10; // replace with actual points calculation

          // Update rank based on points
          location.rank = Math.floor(location.points / 50) + 1;
        }

        // Update user document in Firestore
        await updateDoc(userRef, userData);
        // Return the new journey item
        return { id: journeyItemDocRef.id, ...journeyItem };
      } else {
        console.log("selectedActivity is null");
      }

      onClose();
    } catch (e) {
      console.error("Error adding document: ", e);
      throw e; // re-throw the error to stop execution
    } finally {
      setLoading(false);
    }

    console.log("Ending createNewJourneyItem function");
  };

  const validateVideoLink = (relatedExperience) => (videoLink) => {
    console.log("videoLink:", videoLink);
    console.log("relatedExperience:", relatedExperience);
    const youtubeRegex =
      /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
    const instagramRegex =
      /(http|https):\/\/(www\.)?instagram.com\/(p|reel|tv)\/(.*\/)?/;
    const tiktokRegex =
      /(http|https):\/\/(www\.|vt\.)?tiktok.com\/(@.+\/video\/.+|Z.+\/)/;

    if (relatedExperience && !videoLink) {
      return "A video link is required when an experience is selected";
    } else if (
      videoLink &&
      !(
        youtubeRegex.test(videoLink) ||
        instagramRegex.test(videoLink) ||
        tiktokRegex.test(videoLink)
      )
    ) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Please enter a valid YouTube, Instagram, or TikTok link",
        customClass: {
          container: "swal",
        },
      });
      return false;
    }
    return true;
  };

  const validateExperience = (experience, allValues) => {
    const relatedExperience = allValues.experiences.find(
      (exp) => exp.experience === experience
    );

    if (experience && relatedExperience && !relatedExperience.videoLink) {
      return "A video link is required when an experience is selected";
    }
    return true;
  };

  const onSubmit = async (data) => {
    console.log("Submitting Form");
    console.log(data);

    // Map over the experiences array in the form data
    data.experiences = data.experiences.map((experience) => {
      // If a custom experience was entered, use it as the name
      if (experience.customExperience) {
        return {
          ...experience,
          name: experience.customExperience,
        };
      } else {
        // If a predefined experience was selected, use its name
        const selectedExperience = experiencePrompts[experience.experience];
        return {
          ...experience,
          name: selectedExperience ? selectedExperience.name : undefined,
        };
      }
    });

    // Filter out any experiences that don't have a selected experience or a custom experience
    const validExperiences = data.experiences.filter(
      (experience) =>
        experience.experience !== "" || experience.customExperience !== ""
    );

    console.log(data);

    console.log("Valid experiences:", validExperiences);

    // Prepare the data to be sent to the database
    const dataToSend = {
      ...data,
      experiences: validExperiences,
      category: category,
      photos: uploadMetadata,
    };

    // Check if any experiences are missing a video link
    const missingVideoLink = validExperiences.some(
      (experience) => !experience.videoLink
    );

    if (missingVideoLink) {
      console.log("Missing video link for an experience");

      // Show a SweetAlert
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "A video link is required when an experience is selected",
        customClass: {
          container: "swal",
        },
      });

      return;
    }

    if (!category) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Category is required!",
        customClass: {
          container: "swal",
        },
      });
      return;
    }

    if (selectedActivity === null) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Please select an Activity!",
        customClass: {
          container: "swal",
        },
      });
      return;
    }

    try {
      console.log("Creating new journey item...");
      const newJourneyItem = await createNewJourneyItem(dataToSend);
      console.log("New journey item created:", newJourneyItem);

      // If a journey is selected, add the new item to the journey
      if (data.journey) {
        console.log("Adding new item to journey...");

        // Get a reference to the journey document
        const journeyRef = doc(db, "Journeys", data.journey.id);

        // Fetch the current journey document
        const journeySnap = await getDoc(journeyRef);
        let journeyData = journeySnap.data();

        // If the journey doesn't have an items array, create it
        if (!journeyData.items) {
          journeyData.items = [];
        }

        // Add the new item to the journey's items array
        journeyData.items.push({
          id: newJourneyItem.id,
          category: newJourneyItem.category,
          name: newJourneyItem.name,
        });

        // Update the journey document with the new items array
        await updateDoc(journeyRef, {
          items: journeyData.items,
        });

        console.log("New item added to journey");
      }

      console.log("Fetching user stats...");
      const userRef = doc(db, "Users", user.uid); // replace 'user.uid' with actual user id

      // Fetch updated user stats
      console.log("Fetching updated user stats...");
      const updatedUserSnap = await getDoc(userRef);
      let updatedUserData = updatedUserSnap.data();
      const updatedCategoryStats = updatedUserData.stats[dataToSend.category];

      Swal.fire({
        icon: "success",
        title: "Success!",
        text: `New journey item created successfully! You now have ${updatedCategoryStats.totalSubmissions} total submissions and ${updatedCategoryStats.points} points in this category.`,
        customClass: {
          container: "swal",
        },
      });
      onClose();
      setRefresh(true);
    } catch (error) {
      console.error("Error:", error);
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
        customClass: {
          container: "swal",
        },
      });
    }
  };

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)} style={{ marginLeft: "20px" }}>
        <Grid className="formControl" container direction="column" spacing={2}>
          {" "}
          <>
            <Grid item>
              <InputLabel className="inputLabel" id="journey-label">
                Add to an existing Journey (optional - this can be added later)
              </InputLabel>
              <Controller
                name="journey"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <FormControl>
                    <InputLabel
                      id="journey-label"
                      shrink={field.value ? true : false}
                    >
                      {field.value
                        ? "Add to an existing Journey"
                        : "Select a journey"}
                    </InputLabel>
                    <Select
                      {...field}
                      labelId="journey-label"
                      renderValue={(selected) => {
                        if (selected === "") {
                          return <em>Select a journey</em>;
                        } else {
                          return selected.name;
                        }
                      }}
                    >
                      {journeys.map((journey) => (
                        <MenuItem key={journey.id} value={journey}>
                          {journey.name}
                        </MenuItem>
                      ))}
                    </Select>
                    <FormHelperText>
                      Optional - this can be added later
                    </FormHelperText>
                  </FormControl>
                )}
              />
            </Grid>
            <Grid item>
              <InputLabel className="inputLabel" id="hotel-label">
                Find Your Activity
              </InputLabel>
              <PlacesAutocomplete
                value={autocompleteValue}
                onChange={setAutocompleteValue}
                searchOptions={{
                  types: ["establishment"],
                }}
                onSelect={(description, placeId) => {
                  setAutocompleteValue(description);

                  const request = {
                    placeId: placeId,
                    fields: [
                      "name",
                      "formatted_address",
                      "photos",
                      "types",
                      "geometry",
                      "address_components",
                    ],
                  };

                  const service = new google.maps.places.PlacesService(
                    document.createElement("div")
                  );
                  service.getDetails(request, (place, status) => {
                    if (
                      status === google.maps.places.PlacesServiceStatus.OK &&
                      place.geometry &&
                      place.address_components
                    ) {
                      const lat = place.geometry.location.lat();
                      const lng = place.geometry.location.lng();
                      const geopoint = new GeoPoint(lat, lng);

                      let city,
                        state,
                        country,
                        countryCode = "N/A"; // Initialize city, state, country, and countryCode to "N/A"

                      for (
                        let i = 0;
                        i < place.address_components.length;
                        i++
                      ) {
                        const component = place.address_components[i];

                        if (component.types.includes("locality")) {
                          city = component.short_name;
                          console.log("locality: " + component.short_name);
                        }

                        if (
                          component.types.includes(
                            "administrative_area_level_1"
                          )
                        ) {
                          state = component.long_name; // Keep using long_name for the state
                        }

                        if (component.types.includes("country")) {
                          country = component.long_name; // Keep using long_name for the country
                          countryCode = component.short_name; // short_name is the country code
                        }
                      }

                      // ...

                      const activityData = {
                        name: place.name,
                        address: place.formatted_address,
                        image: place.photos ? place.photos[0].getUrl() : null,
                        itemType: "Activity",
                        geopoint: geopoint,
                        placeId: placeId,
                        city: city || "N/A", // If city is undefined, use "N/A" instead
                        state: state || "N/A", // If state is undefined, use "N/A" instead
                        country: country || "N/A", // If country is undefined, use "N/A" instead
                        countryCode: countryCode || "N/A", // If countryCode is undefined, use "N/A" instead
                      };
                      setSelectedActivity(activityData);
                    } else {
                      console.error(
                        "Error: No location data available for this place.",
                        "Status:",
                        status,
                        "Place:",
                        place
                      );
                    }
                  });
                }}
              >
                {({
                  getInputProps,
                  suggestions,
                  getSuggestionItemProps,
                  loading,
                }) => (
                  <div>
                    <input
                      {...getInputProps({
                        placeholder: "Search for activities...",
                        className: "hotel-input",
                        style: {
                          width: "auto",
                          height: "40px",
                          padding: "10px",
                        },
                      })}
                    />
                    <div>
                      {loading && <div>Loading...</div>}
                      {suggestions.map((suggestion) => {
                        const style = {
                          backgroundColor: suggestion.active
                            ? "#41b6e6"
                            : "#fff",
                          cursor: "pointer",
                        };

                        return (
                          <div
                            {...getSuggestionItemProps(suggestion, {
                              style,
                              key: suggestion.placeId,
                            })}
                          >
                            {suggestion.description}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                )}
              </PlacesAutocomplete>
            </Grid>
            <Grid item>
              <InputLabel className="inputLabel">Start Date</InputLabel>
              <Controller
                name="startDate"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    {...field}
                    type="date"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                )}
              />
              <FormHelperText error>
                {errors.startDate && errors.startDate.message}
              </FormHelperText>
            </Grid>
            <Grid item>
              <InputLabel className="inputLabel">End Date</InputLabel>
              <Controller
                name="endDate"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    {...field}
                    type="date"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item>
              <hr />
              <Typography
                className="inputLabel"
                style={{ marginBottom: "20px" }}
              >
                Add Experience (optional - this can be added later)
              </Typography>
              {fields.map((field, index) => {
                const selectedExperience = watch(
                  `experiences[${index}].experience`
                );
                return (
                  <Box mt={2} mb={2} key={field.id}>
                    <FormControl>
                      <InputLabel id={`experience-label-${index}`}>
                        Experience
                      </InputLabel>
                      <Controller
                        name={`experiences[${index}].experience`}
                        control={control}
                        defaultValue=""
                        render={({ field: fieldProps }) => (
                          <Select
                            labelId={`experience-label-${index}`}
                            {...fieldProps}
                            onChange={(e) => {
                              fieldProps.onChange(e);
                              setValue(
                                `experiences[${index}].customExperience`,
                                ""
                              );
                            }}
                            renderValue={(selected) => {
                              if (selected === "") {
                                return <em>Select an experience</em>;
                              } else {
                                return experiencePrompts[selected].name;
                              }
                            }}
                            className="select"
                          >
                            {experiencePrompts.map((experience, index) => (
                              <MenuItem key={index} value={index}>
                                {experience.name}
                              </MenuItem>
                            ))}
                          </Select>
                        )}
                      />
                      <FormHelperText>
                        Select an experience from the list or add your own
                      </FormHelperText>
                      <Typography
                        className="inputLabel"
                        style={{ marginTop: "10px" }}
                      >
                        Custom Experience
                      </Typography>
                      <Controller
                        name={`experiences[${index}].customExperience`}
                        control={control}
                        defaultValue=""
                        render={({ field }) => (
                          <TextField
                            {...field}
                            label="Custom Experience"
                            fullWidth
                            onChange={(e) => {
                              field.onChange(e);
                              setValue(`experiences[${index}].experience`, "");
                              console.log(e.target.value);
                            }}
                          />
                        )}
                      />
                      <FormHelperText>
                        Create your own experience
                      </FormHelperText>
                      <Typography
                        className="inputLabel"
                        style={{ marginTop: "10px" }}
                      >
                        About Experience
                      </Typography>
                      <Typography variant="body1">
                        {experiencePrompts[selectedExperience]?.description}
                      </Typography>
                      <Box mt={2}>
                        <Typography
                          className="inputLabel"
                          style={{ marginTop: "10px" }}
                        >
                          Video Link
                        </Typography>
                        <FormControl>
                          <Controller
                            name={`experiences[${index}].videoLink`}
                            control={control}
                            defaultValue=""
                            rules={{
                              validate: validateVideoLink(
                                watch(`experiences[${index}].experience`) ||
                                  watch(
                                    `experiences[${index}].customExperience`
                                  )
                              ),
                            }}
                            render={({ field }) => (
                              <TextField
                                {...field}
                                label="Video Link"
                                fullWidth
                              />
                            )}
                          />
                          <FormHelperText>
                            Enter the link to your YouTube, Instagram or TikTok
                            video
                          </FormHelperText>
                        </FormControl>
                      </Box>
                      <Box display="flex" alignItems="center">
                        <IconButton
                          onClick={() => remove(index)}
                          disabled={index === 0}
                        >
                          <RemoveIcon />
                        </IconButton>
                        <IconButton
                          onClick={() =>
                            append({ experience: "", videoLink: "" })
                          }
                        >
                          <AddIcon />
                        </IconButton>
                      </Box>
                    </FormControl>
                  </Box>
                );
              })}
            </Grid>
            <hr />

            <Grid item xs={12}>
              <InputLabel className="inputLabel">
                Upload Photos (Optional - this can be added later)
              </InputLabel>
              <FormControl style={{ width: "100%" }}>
                <div style={{ width: "100%", border: "2px solid #1bb6ca" }}>
                  <FilePond
                    files={files}
                    allowMultiple={true}
                    maxFileSize="5MB" // Set the maximum file size to 5MB
                    acceptedFileTypes={["image/jpeg", "image/png"]} // Limit file types to JPEG and PNG
                    onprocessfile={(error, file) => {
                      console.log("File processed", file.serverId);
                    }}
                    server={{ process: handleProcess }}
                    onupdatefiles={setFiles}
                    labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
                    labelMaxFileSizeExceeded="File is too large" // Custom message for files that exceed the size limit
                    labelMaxFileSize="Maximum file size is 5MB" // Message showing the max file size
                    labelFileTypeNotAllowed="Invalid file type" // Custom message for disallowed file types
                    fileValidateTypeLabelExpectedTypes="Expects JPEG or PNG" // Message showing the expected file types
                  />
                </div>
              </FormControl>
            </Grid>

            <hr />
            <Grid item xs={12}>
              <InputLabel className="inputLabel">Write a Review</InputLabel>
              <FormControl style={{ width: "100%" }}>
                <Controller
                  name="review"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Review"
                      multiline
                      rows={4}
                      variant="outlined"
                      fullWidth
                    />
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item>
              <Controller
                name="rating"
                control={control}
                defaultValue={0}
                render={({ field }) => (
                  <div>
                    <InputLabel className="inputLabel" id="hotel-label">
                      Rate your experience:
                    </InputLabel>
                    <Rating
                      name="rating"
                      value={field.value}
                      onChange={(event, newValue) => {
                        field.onChange(newValue);
                      }}
                    />
                  </div>
                )}
              />
            </Grid>
            {/*
              <Grid item>
                <InputLabel className="inputLabel" id="journey-label">
                  Link a Journey
                </InputLabel>
                <FormControl>
                  <InputLabel id="journey-label">Journey</InputLabel>
                  <Controller
                    name="journey"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <Select labelId="journey-label" {...field}>
                        {journeys.length > 0 ? (
                          journeys.map((journey) => (
                            <MenuItem key={journey.id} value={journey.id}>
                              {journey.journeyName}
                            </MenuItem>
                          ))
                        ) : (
                          <MenuItem disabled>No journeys</MenuItem>
                        )}
                      </Select>
                    )}
                  />
                  <FormHelperText>
                    Choose a journey to link this item to
                  </FormHelperText>
                </FormControl>
              </Grid>
                        */}
          </>
          <Grid item>
            <div className="formButtons">
              <button className="submitButton" type="submit">
                Submit
              </button>
              <button className="cancelButton" type="button" onClick={onClose}>
                Cancel
              </button>
            </div>
          </Grid>
        </Grid>
      </form>
      {loading && <CircularProgress />}
    </div>
  );
}

export default NewActivityItem;
