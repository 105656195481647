import React, {
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
  useContext,
} from "react";
import { Link } from "react-router-dom";
import Navbar from "../../Components/Navbar/Navbar";
import Footer from "../../Components/footer/footer.js";
import JourneyItem from "../../Components/homeFeed/homeFeed";
import { initializeApp } from "firebase/app";
import { getFirestore, collection, getDocs } from "firebase/firestore";
import DirectionsBoatIcon from "@mui/icons-material/DirectionsBoat";
import { db } from "../../firebase.js";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Map from "../../Components/homeMap/homeMap";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import Swal from "sweetalert2";
import JourneyForm from "../../Components/items/newItem.js";
import NewJourney from "../../Components/items/newJourney.js";
import UserContext from "../../UserContext";
import Tooltip from "@mui/material/Tooltip";
import Popover from "@mui/material/Popover";
import IconButton from "@mui/material/IconButton";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import Chip from "@mui/material/Chip";
import "./HomePage.css";
console.log(db);

function HomePage() {
  const [journeyItems, setJourneyItems] = useState([]);
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);
  const [openJourney, setOpenJourney] = useState(false);
  const handleCloseJourney = () => setOpenJourney(false);
  const [items, setItems] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const user = useContext(UserContext);
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorEl2, setAnchorEl2] = useState(null);
  console.log(user);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClick2 = (event) => {
    setAnchorEl2(event.currentTarget);
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
  };

  const handleClosePopover2 = () => {
    setAnchorEl2(null);
  };

  const openPopover = Boolean(anchorEl);
  const openPopover2 = Boolean(anchorEl2);
  const id = openPopover ? "simple-popover" : undefined;
  const id2 = openPopover2 ? "simple-popover-2" : undefined;

  const handleOpen = () => {
    if (!user) {
      Swal.fire({
        icon: "info",
        title: "Please sign in",
        text: "You need to be signed in to add an experience",
      });
    } else {
      setOpen(true);
    }
  };

  const handleOpenJourney = () => {
    if (!user) {
      Swal.fire({
        icon: "info",
        title: "Please sign in",
        text: "You need to be signed in to add a journey",
      });
    } else {
      setOpenJourney(true);
    }
  };

  return (
    <div className="App">
      <Navbar />
      <Map items={items} />
      <header className="App-header">
        <div className="tagLine">
          <p className="homeText">Kaleido</p>
          <p className="highlight"> Travel</p>
        </div>
        <div>
          <p className="heroP">
            Your interactive diary for sharing your journeys and inspiring
            perfect travel experiences for our community.
          </p>

          <Link to="/Cruise">
            <Chip label="Cruises" variant="outlined" className="homeChips" avatar={<DirectionsBoatIcon />} />
          </Link>
          <div className="homePageButtons">
            <Button
              variant="contained"
              onClick={handleOpen}
              sx={{ backgroundColor: "#1bb6ca", margin: "10px" }}
            >
              New Experience
            </Button>
            <IconButton
              aria-describedby={id}
              onClick={handleClick}
              sx={{ marginLeft: "-10px" }}
            >
              <HelpOutlineIcon />
            </IconButton>
            <Popover
              id={id}
              open={openPopover}
              anchorEl={anchorEl}
              onClose={handleClosePopover}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
            >
              <div style={{ padding: "20px" }}>
                An Experience is a single event or activity that you want to
                share with the community. It could be a restaurant, a hotel, a
                tour, or anything else that you want to share with others.
              </div>
            </Popover>
            <Modal
              open={open}
              onClose={handleClose}
              style={{
                display: "flex",
                justifyContent: "center",
                overflow: "auto",
              }}
            >
              <JourneyForm
                user={user ? user : null}
                onClose={handleClose}
                setRefresh={setRefresh} // This will be used to refresh the feed after a new item is added
              />
            </Modal>
            <Button
              variant="contained"
              onClick={handleOpenJourney}
              sx={{ backgroundColor: "#1bb6ca", margin: "10px" }}
            >
              New Journey
            </Button>
            <IconButton
              aria-describedby={id2}
              onClick={handleClick2}
              sx={{ marginLeft: "-10px" }}
            >
              <HelpOutlineIcon />
            </IconButton>
            <Popover
              id={id2}
              open={openPopover2}
              anchorEl={anchorEl2}
              onClose={handleClosePopover2}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
            >
              <div style={{ padding: "20px" }}>
                A Journey is a collection of Experiences that you want to share
                with the community. It could be a trip, a vacation, or a series
                of events that you want to share with others.
              </div>
            </Popover>
            {/*<Button
              variant="contained"
              onClick={handleOpenJourney}
              sx={{ backgroundColor: "#1bb6ca", margin: "10px" }}
            >
              New Dream
            </Button>*/}
            <Modal
              open={openJourney}
              onClose={handleCloseJourney}
              style={{
                display: "flex",
                justifyContent: "center",
                overflow: "auto",
              }}
            >
              <NewJourney
                user={user ? user : null}
                onClose={handleCloseJourney}
              />
            </Modal>
          </div>
        </div>
        <JourneyItem
          userId={user ? user.uid : null}
          user={user ? user : null}
          setItems={setItems}
          items={items}
          refresh={refresh} // This will be used to refresh the feed after a new item is added
          setRefresh={setRefresh}
        />
      </header>
      <Footer />
    </div>
  );
}

export default HomePage;
