import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import {
  getFirestore,
  getDocs,
  collection,
  addDoc,
  getDoc,
  serverTimestamp,
  Timestamp,
  query,
  where,
  arrayUnion,
  doc, // Add this line to import the 'doc' function
  updateDoc, // Add this line to import the 'updateDoc' function
} from "firebase/firestore";
import { db } from "../../firebase";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  Button,
  TextField,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import Swal from "sweetalert2";
import "./newItem.css";
import { Category } from "@mui/icons-material";
import { format } from "date-fns";

function NewJourney({ user, onClose }) {
  const { control, handleSubmit, watch } = useForm();
  const [cruises, setCruises] = useState([]);
  const [ships, setShips] = useState([]);
  const [loading, setLoading] = useState(true);
  const selectedCruise = watch("cruise");

  useEffect(() => {
    const fetchCruises = async () => {
      const cruisesCollection = collection(db, "Cruises");
      const cruisesSnapshot = await getDocs(cruisesCollection);
      const cruisesList = cruisesSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setCruises(cruisesList);
      setLoading(false);
    };

    fetchCruises();
  }, []);

  useEffect(() => {
    if (selectedCruise) {
      const fetchShips = async () => {
        const shipsCollection = collection(
          db,
          `Cruises/${selectedCruise}/Ships`
        );
        const shipsSnapshot = await getDocs(shipsCollection);
        const shipsList = shipsSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setShips(shipsList);
      };

      fetchShips();
    }
  }, [selectedCruise]);

  const onSubmit = async (data) => {
    try {
      const selectedCruise = cruises.find(
        (cruise) => cruise.id === data.cruise
      );
      const selectedShip = ships.find((ship) => ship.id === data.ship);

      // Convert dates to Firestore Timestamps for storage
      const departureTimestamp = Timestamp.fromDate(
        new Date(data.departureDate)
      );
      const endTimestamp = Timestamp.fromDate(new Date(data.endDate));

      // Format the departureDate to only include the date part for comparison
      const formattedDepartureDate = format(
        new Date(data.departureDate),
        "yyyy-MM-dd"
      );

      // Check if a record already exists
      const querySnapshot = await getDocs(
        query(
          collection(db, "CruiseJourneys"),
          where("cruise.id", "==", selectedCruise.id),
          where("ship.id", "==", selectedShip.id),
          where("departureDate", "==", departureTimestamp)
        )
      );

      if (!querySnapshot.empty) {
        // Record exists, redirect to /cruise/{id}
        const existingDoc = querySnapshot.docs[0];
        window.location.href = `/cruise/${existingDoc.id}`;
        return;
      }

      // Add new document if no record exists
      const docRef = await addDoc(collection(db, "CruiseJourneys"), {
        cruise: {
          id: selectedCruise.id,
          name: selectedCruise.name,
          image: selectedCruise.imageUrl,
        },
        ship: {
          id: selectedShip.id,
          name: selectedShip.name,
        },
        departureDate: departureTimestamp,
        endDate: endTimestamp,
        departurePort: data.departurePort,
        user: {
          uid: user.uid,
          userName: user.userName,
          email: user.email,
        },
        users: arrayUnion({ userName: user.userName, uid: user.uid }),
        timestamp: serverTimestamp(),
        category: "CruiseJourney",
      });

      // Add data to the Users collection under a sub-object called cruises
      // Update the Users collection
      const userRef = doc(db, "Users", user.uid); // replace 'user.uid' with actual user id
      const userSnap = await getDoc(userRef);
      let userData = userSnap.data(); // Use a different variable name here

      // Initialize stats field if it doesn't exist
      if (!userData.stats) {
        userData.stats = {
          rank: 1, // Initialize rank
          points: 0, // Initialize overall points
        };
      } else {
        // Initialize rank field if it doesn't exist
        if (userData.stats.rank === undefined) {
          userData.stats.rank = 1; // Initialize rank
        }

        if (typeof userData.stats.points !== "number") {
          // If userData.stats.points exists but is not a number, reset it to 0
          userData.stats.points = 0;
        }
      }

      // Initialize category field within stats if it doesn't exist
      if (!userData.stats.CruiseJourney) {
        userData.stats.CruiseJourney = {
          totalSubmissions: 0,
          totalCruises: 0,
          uniqueSubmissions: {},
          uniqueCruises: {},
          points: 0,
          rank: 1, // Initialize rank for category
          cruiseline: {}, // Initialize cruiseline object
          cruiseship: {}, // Initialize cruiseship object
        };
      }

      const categoryStats = userData.stats.CruiseJourney;
      categoryStats.totalSubmissions += 1;
      categoryStats.totalCruises += 1;
      categoryStats.points += 10; // replace with actual points calculation
      userData.stats.points += 10; // Increment overall points

      // Update rank based on points
      userData.stats.rank = Math.floor(userData.stats.points / 100) + 1;

      // Update category rank based on category points
      categoryStats.rank = Math.floor(categoryStats.points / 50) + 1;

      // Ensure uniqueCruises is initialized
      if (!categoryStats.uniqueCruises) {
        categoryStats.uniqueCruises = {};
      }
      console.log(docRef.id);
      // Add unique cruise
      if (!categoryStats.uniqueCruises[docRef.id]) {
        categoryStats.uniqueCruises[docRef.id] = {
          cruiseline: selectedCruise.name,
          cruiseship: selectedShip.name,
          cruiseDate: departureTimestamp,
          cruisePort: data.departurePort,
          id: docRef.id,
        };
      }

      //add count for how many times user has joined a cruise
      if (!categoryStats.cruiseline[docRef.id]) {
        categoryStats.cruiseline[selectedCruise.id] = {
          cruiseline: selectedCruise.name,
          count: 1,
        };
      } else {
        categoryStats.cruiseline[selectedCruise.id].count += 1;
      }

      //add count for how many times user has joined a ship
      if (!categoryStats.cruiseship[selectedShip.id]) {
        categoryStats.cruiseship[selectedShip.id] = {
          cruiseship: selectedShip.name,
          count: 1,
        };
      } else {
        categoryStats.cruiseship[selectedShip.id].count += 1;
      }

      // Update user document in Firestore
      await updateDoc(userRef, userData);
      Swal.fire("Success", "New cruise journey added!", "success").then(() => {
        window.location.href = `/cruise/${docRef.id}`;
      });
      onClose();
    } catch (error) {
      console.error("Error adding document: ", error);
      Swal.fire("Error", "There was an error adding the document.", "error");
    }
  };

  return (
    <div className="form-container">
      <div className="Itemform">
        <div className="formHeading">
          <h1 className="headingdark">Add a new </h1>
          <h1 className="headingLight">Cruise</h1>
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              {loading ? (
                <CircularProgress />
              ) : (
                <FormControl fullWidth>
                  <InputLabel id="cruise-label">Cruise</InputLabel>
                  <Controller
                    name="cruise"
                    control={control}
                    render={({ field }) => (
                      <Select {...field} labelId="cruise-label" label="Cruise">
                        {cruises.map((cruise) => (
                          <MenuItem key={cruise.id} value={cruise.id}>
                            {cruise.name}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  />
                </FormControl>
              )}
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel id="ship-label">Ship</InputLabel>
                <Controller
                  name="ship"
                  control={control}
                  render={({ field }) => (
                    <Select {...field} labelId="ship-label" label="Ship">
                      {ships.map((ship) => (
                        <MenuItem key={ship.id} value={ship.id}>
                          {ship.name}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="departurePort"
                control={control}
                render={({ field }) => (
                  <TextField {...field} label="Departure Port" fullWidth />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="departureDate"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Departure Date"
                    type="date"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    fullWidth
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="endDate"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Return Date"
                    type="date"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    fullWidth
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Button className="submitButton" type="submit">
                Submit
              </Button>
              <Button className="cancelButton" type="button" onClick={onClose}>
                Cancel
              </Button>
            </Grid>
          </Grid>
        </form>
      </div>
    </div>
  );
}

export default NewJourney;
